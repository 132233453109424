import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { setAccessToken } from '../../domain/auth/storage'
import packageJson from '../../../package.json'
import { addError } from '../errors'

// eslint-disable-next-line import/no-mutable-exports
let apolloClient: ApolloClient<NormalizedCacheObject>

export const createApolloClient = (
  hostname: string,
  port: string,
  useSecureScheme: boolean,
  getToken?: Function,
  setToken?: Function,
): ApolloClient<NormalizedCacheObject> => {
  if (apolloClient) {
    return apolloClient
  }

  const httpUrl = `${useSecureScheme ? 'https' : 'http'}://${hostname}:${port}/graphql`

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          vendorAccounts: {
            merge: false
          }
        }
      },
      FeatureInterests: {
        keyFields: [], // singleton
        merge: true
      },
      User: {
        keyFields: [], // singleton
        merge: true
      },
      VendorLink: {
        keyFields: ['id'],
      },
    }
  })

  const httpLink = () =>
    new BatchHttpLink({
      uri: httpUrl,
      batchMax: 8, // No more than 8 operations per batch
      batchInterval: 10, // Wait no more than 10ms after first batched operation
      headers: {
        'hifi-product': packageJson.name
      }
    })

  const authLink = setContext(async (request: any, { headers }: any) => {
    const { operationName } = request
    const useToken = operationName

    const bearer = useToken && !!getToken ? `Bearer ${await getToken()}` : ''

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: bearer
      }
    }
  })

  const updateTokenAfterware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext()
      const { response: { headers } } = context

      const authorizationHeader = headers.get('Authorization')

      const { operationName } = operation
      
      const shouldIgnoreHeaderCheck = ['Verify', 'Login'].includes(operationName)

      if (shouldIgnoreHeaderCheck) return response
      
      if (!authorizationHeader || typeof authorizationHeader !== 'string') {
        addError(new Error(`Invalid Authorization header received: ${authorizationHeader}`))
        setToken?.call('')
        return response
      }

      const [, token] = authorizationHeader.split('Bearer ')

      if (!token) {
        addError(new Error(`Invalid Authorization header received: ${authorizationHeader}`))
        setToken?.call('')
        return response
      }

      setAccessToken(token)
      return response
    })
  })

  const links = () => authLink.concat(httpLink())

  const errorHandler = onError((data: any) => {
    const { graphQLErrors, networkError } = data

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }: any) =>
        console.warn(
          '[GraphQL error]: Message:', message, ', Location:', locations, ', Path:', path
        ))
    }

    if (networkError) console.warn('[Network error]:', networkError)
  })

  apolloClient = new ApolloClient({
    assumeImmutableResults: true,
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    },
    link: ApolloLink.from([
      updateTokenAfterware,
      errorHandler,
      links()
    ])
  })

  return apolloClient
}

export { apolloClient }
