import { useState, createContext } from 'react'

import { LoadingStore } from './types'

export const LoadingContext = createContext<LoadingStore>({
  onBack: [null, () => undefined],
  loading: [false, () => undefined],
  refreshing: [false, () => undefined],
  loadingSilent: [false, () => undefined],
  initializing: [false, () => undefined],
})

export const LoadingProvider = ({ initializingState, children }: any) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSilent, setLoadingSilent] = useState<boolean>(false)
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [initializing, setInitializing] = initializingState ?? useState<boolean>(false)
  const [onBack, setOnBack] = useState<any>(null)

  const store: LoadingStore = {
    onBack: [onBack, setOnBack],
    loading: [loading, setLoading],
    loadingSilent: [loadingSilent, setLoadingSilent],
    refreshing: [refreshing, setRefreshing],
    initializing: [initializing, setInitializing],
  }

  return (
    <LoadingContext.Provider value={store}>
      {children}
    </LoadingContext.Provider>
  )
}
