import { useContext } from 'react'

import { Dashboard, DashboardStore, DraftState } from './types'
import { DashboardContext, DashboardTotals, emptyDashboard } from './provider'

export function useDashboards(): DashboardStore {
  return useContext(DashboardContext)
}

export function useClients(): Dashboard[] {
  const { dashboards: [dashboards] } = useContext(DashboardContext)

  return dashboards
}

export function useLoadingDashboards(): boolean {
  const { loadingDashboards: [loadingDashboards] } = useContext(DashboardContext)

  return loadingDashboards
}

export function useDashboard(id: string): Dashboard {
  const { dashboards: [dashboards] } = useContext(DashboardContext)

  return dashboards.find((dashboard) => dashboard.id === id) || emptyDashboard
}

export function useDraft(): DraftState {
  const { draft: [draft, setDraft] } = useContext(DashboardContext)

  return [draft, setDraft]
}

export function useTotals() {
  const [totals, addTotals] = useContext(DashboardTotals)

  return [totals, addTotals]
}
