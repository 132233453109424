import { useContext } from 'react'

import { RoyaltyContext } from './provider'

export function useMetrics() {
  const { metrics } = useContext(RoyaltyContext)

  return metrics
}

export function useTotals() {
  const { totals } = useContext(RoyaltyContext)

  return { totals }
}

export function useBreakdowns() {
  const { breakdowns } = useContext(RoyaltyContext)

  return breakdowns
}

export function useDateType() {
  const { dateType } = useContext(RoyaltyContext)

  return dateType
}

export function useEndDate() {
  const { endDate } = useContext(RoyaltyContext)

  return endDate
}

export function useStartDate() {
  const { startDate } = useContext(RoyaltyContext)

  return startDate
}

export function useDateRange() {
  const { currentDateRange } = useContext(RoyaltyContext)

  return currentDateRange
}

export function useDatePickerStart() {
  const { currentDatePickerStart } = useContext(RoyaltyContext)

  return currentDatePickerStart
}

export function useNormalizedDownload() {
  const { normalizedDownload } = useContext(RoyaltyContext)

  return normalizedDownload
}

