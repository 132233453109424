import { gql } from '@apollo/client'

export const METRICS_QUERY = gql` 
  query Metrics {
    metrics {
      countries
      projects
    }
  }
`

export const CURRENCIES_QUERY = gql` 
  query Currencies($filters: RoyaltyFilters!) {
    currencies(filters: $filters)
  }
`

export const TOTALS_QUERY = gql` 
  query Totals($filters: RoyaltyFilters!) {
    totals(filters: $filters) {
      full { 
        total {
          amount
          currency
        }
      }
      period {
        date
        total {
          amount
          currency
        }
      }
    }
  }
`
