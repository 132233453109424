import {
  AppBar, Box,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { useEffect, useState } from 'react'

import { colors } from '../../../global/colors'
import { Routes } from '../../constants'

export function HeaderIntro() {
  const history = useHistory()

  const [showBack, setShowBack] = useState(false)

  const onGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    const unlisten = history.listen((location: any) => {
      const path = location.pathname
      const baseRoutes = [Routes.intro]
      setShowBack(!baseRoutes.includes(path))
    })

    return () => unlisten()
  }, [])

  return (
    <AppBar
      position="fixed"
      elevation={0}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        color: colors.black,
        backgroundColor: colors.background,
        height: 50,
      }}>
      {!showBack ? undefined : (
        <div
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          data-ph-capture-attribute-details={'Clicked [Go Back]'}
          onClick={() => onGoBack()}
          onKeyPress={() => onGoBack()}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 96,
              minHeight: 50,
              paddingLeft: 24,
              paddingTop: 24,
            }}>
            <ArrowBack style={{ fontSize: 16 }} />
            <span style={{
              fontSize: 14, paddingRight: 4, paddingLeft: 4, paddingTop: 2.5, paddingBottom: 2.5, textTransform: 'uppercase'
            }}>
              Go Back
            </span>
          </Box>
        </div>
      )}
    </AppBar>
  )
}
