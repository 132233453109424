export const colors = {
  blue500: '#006ae3',
  darkModalBackground: 'rgba(0,0,0,0.7)',
  lightModalBackground: 'rgba(255,255,255,0.7)',
  grey200: '#F2F2F2',
  grey300: '#e6e6e6',
  grey400: '#c7c7c7',
  grey500: '#9e9e9e',
  grey600: '#828282',
  grey700: '#6d6c6b',
  greyDark: '#A0A0A0',
  greyAlmost: '#e6e6e6',
  lightBlueGrey: '#b1b3bd',
  offBlack: '#151715',
  altBlack: '#00000099',
  black: '#000000',
  pink100: '#ffe8f5',
  pink200: '#fe98d0',
  pink500: '#ff008c',
  pinkShadow: '#EC358A',
  pinkTransparency: '#FF008C22',
  white: '#ffffff',
  offWhite: '#F8F8F8',
  background: '#F8F8F8',
  whiteTransparency: '#ffffffAA',
  transparent: '#00000000',
  black_60: '#666666',
  disabled_btn_bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #000000',
  disabled_text_color: '#4C4C4C',
  disabled_icon_color: '#B2B2B2',
  disabled_cell_bg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.98), rgba(255, 255, 255, 0.98)), #000000',
}
