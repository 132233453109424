const isSafariOnly = (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)

export const downloadFileUri = (uri: string, filename: string, openTarget='_blank') => {
  const link = document.createElement('a')
  if (isSafariOnly) {
    link.setAttribute('download', filename)
  } else {
    link.download = filename
  }
  link.href = uri
  link.target = openTarget
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

