/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { TotalState } from './totals/state'

import {
  Breakdown,
  BreakdownItem,
  DateType,
  Metrics,
  RoyaltyFilters,
} from '../types'

export type BreakdownItemExtended = BreakdownItem & { label: string }

export type MetricState = [
  Metrics,
  () => void,
]

export type DateTypeState = [
  DateType,
  (type: DateType) => void,
]

export type DateTimeState = [
  string | null | undefined,
  (dateTime: string | null | undefined) => void,
]

export type DateRangeState = [
  string | null | undefined,
  (dateRange: string | null | undefined) => void,
]

export type DatePickerStartState = [
  string | undefined,
  (dateTime: string | undefined) => void,
]

export type BreakdownsState = [
  Breakdown[],
  () => void,
]

export type NormalizedDownloadState = [
  any | {}, (normalizedDownload: any) => void]

export type RoyaltyStore = {
  metrics: MetricState,
  dateType: DateTypeState,
  endDate: DateTimeState,
  startDate: DateTimeState,
  currentDateRange: DateRangeState,
  currentDatePickerStart: DatePickerStartState,
  totals: TotalState,
  breakdowns: BreakdownsState,
  normalizedDownload: NormalizedDownloadState
}

export type FilterOption = {
  id?: string,
  value: string,
  label?: string,
  filterType: string,
  productType?: string | undefined,
}

export enum Currency {
  AFA = 'AFA',
  ALL = 'ALL',
  DZD = 'DZD',
  AOA = 'AOA',
  ARS = 'ARS',
  AMD = 'AMD',
  AWG = 'AWG',
  AUD = 'AUD',
  AZN = 'AZN',
  BSD = 'BSD',
  BHD = 'BHD',
  BDT = 'BDT',
  BBD = 'BBD',
  BYR = 'BYR',
  BEF = 'BEF',
  BZD = 'BZD',
  BMD = 'BMD',
  BTN = 'BTN',
  BTC = 'BTC',
  BOB = 'BOB',
  BAM = 'BAM',
  BWP = 'BWP',
  BRL = 'BRL',
  GBP = 'GBP',
  BND = 'BND',
  BGN = 'BGN',
  BIF = 'BIF',
  KHR = 'KHR',
  CAD = 'CAD',
  CVE = 'CVE',
  KYD = 'KYD',
  XOF = 'XOF',
  XAF = 'XAF',
  XPF = 'XPF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  KMF = 'KMF',
  CDF = 'CDF',
  CRC = 'CRC',
  HRK = 'HRK',
  CUC = 'CUC',
  CZK = 'CZK',
  DKK = 'DKK',
  DJF = 'DJF',
  DOP = 'DOP',
  XCD = 'XCD',
  EGP = 'EGP',
  ERN = 'ERN',
  EEK = 'EEK',
  ETB = 'ETB',
  EUR = 'EUR',
  FKP = 'FKP',
  FJD = 'FJD',
  GMD = 'GMD',
  GEL = 'GEL',
  DEM = 'DEM',
  GHS = 'GHS',
  GIP = 'GIP',
  GRD = 'GRD',
  GTQ = 'GTQ',
  GNF = 'GNF',
  GYD = 'GYD',
  HTG = 'HTG',
  HNL = 'HNL',
  HKD = 'HKD',
  HUF = 'HUF',
  ISK = 'ISK',
  INR = 'INR',
  IDR = 'IDR',
  IRR = 'IRR',
  IQD = 'IQD',
  ILS = 'ILS',
  ITL = 'ITL',
  JMD = 'JMD',
  JPY = 'JPY',
  JOD = 'JOD',
  KZT = 'KZT',
  KES = 'KES',
  KWD = 'KWD',
  KGS = 'KGS',
  LAK = 'LAK',
  LVL = 'LVL',
  LBP = 'LBP',
  LSL = 'LSL',
  LRD = 'LRD',
  LYD = 'LYD',
  LTL = 'LTL',
  MOP = 'MOP',
  MKD = 'MKD',
  MGA = 'MGA',
  MWK = 'MWK',
  MYR = 'MYR',
  MVR = 'MVR',
  MRO = 'MRO',
  MUR = 'MUR',
  MXN = 'MXN',
  MDL = 'MDL',
  MNT = 'MNT',
  MAD = 'MAD',
  MZM = 'MZM',
  MMK = 'MMK',
  NAD = 'NAD',
  NPR = 'NPR',
  ANG = 'ANG',
  TWD = 'TWD',
  NZD = 'NZD',
  NIO = 'NIO',
  NGN = 'NGN',
  KPW = 'KPW',
  NOK = 'NOK',
  OMR = 'OMR',
  PKR = 'PKR',
  PAB = 'PAB',
  PGK = 'PGK',
  PYG = 'PYG',
  PEN = 'PEN',
  PHP = 'PHP',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RUB = 'RUB',
  RWF = 'RWF',
  SVC = 'SVC',
  WST = 'WST',
  SAR = 'SAR',
  RSD = 'RSD',
  SCR = 'SCR',
  SLL = 'SLL',
  SGD = 'SGD',
  SKK = 'SKK',
  SBD = 'SBD',
  SOS = 'SOS',
  ZAR = 'ZAR',
  KRW = 'KRW',
  XDR = 'XDR',
  LKR = 'LKR',
  SHP = 'SHP',
  SDG = 'SDG',
  SRD = 'SRD',
  SZL = 'SZL',
  SEK = 'SEK',
  CHF = 'CHF',
  SYP = 'SYP',
  STD = 'STD',
  TJS = 'TJS',
  TZS = 'TZS',
  THB = 'THB',
  TOP = 'TOP',
  TTD = 'TTD',
  TND = 'TND',
  TRY = 'TRY',
  TMT = 'TMT',
  UGX = 'UGX',
  UAH = 'UAH',
  AED = 'AED',
  UYU = 'UYU',
  USD = 'USD',
  UZS = 'UZS',
  VUV = 'VUV',
  VEF = 'VEF',
  VND = 'VND',
  YER = 'YER',
  ZMK = 'ZMK',
}

export enum CurrencySymbol {
  USD = '$',
  AUD = 'A$',
  EUR = '€',
  GBP = '£',
  SEK = 'kr',
}

export const selectCurrencySymbol = (currency: Currency) => {
  switch (currency) {
    case Currency.AUD:
      return CurrencySymbol.AUD
    case Currency.EUR:
      return CurrencySymbol.EUR
    case Currency.GBP:
      return CurrencySymbol.GBP
    case Currency.SEK:
      return CurrencySymbol.SEK
    default:
    case Currency.USD:
      return CurrencySymbol.USD
  }
}

export const currencyList = [
  Currency.AFA,
  Currency.ALL,
  Currency.DZD,
  Currency.AOA,
  Currency.ARS,
  Currency.AMD,
  Currency.AWG,
  Currency.AUD,
  Currency.AZN,
  Currency.BSD,
  Currency.BHD,
  Currency.BDT,
  Currency.BBD,
  Currency.BYR,
  Currency.BEF,
  Currency.BZD,
  Currency.BMD,
  Currency.BTN,
  Currency.BTC,
  Currency.BOB,
  Currency.BAM,
  Currency.BWP,
  Currency.BRL,
  Currency.GBP,
  Currency.BND,
  Currency.BGN,
  Currency.BIF,
  Currency.KHR,
  Currency.CAD,
  Currency.CVE,
  Currency.KYD,
  Currency.XOF,
  Currency.XAF,
  Currency.XPF,
  Currency.CLP,
  Currency.CNY,
  Currency.COP,
  Currency.KMF,
  Currency.CDF,
  Currency.CRC,
  Currency.HRK,
  Currency.CUC,
  Currency.CZK,
  Currency.DKK,
  Currency.DJF,
  Currency.DOP,
  Currency.XCD,
  Currency.EGP,
  Currency.ERN,
  Currency.EEK,
  Currency.ETB,
  Currency.EUR,
  Currency.FKP,
  Currency.FJD,
  Currency.GMD,
  Currency.GEL,
  Currency.DEM,
  Currency.GHS,
  Currency.GIP,
  Currency.GRD,
  Currency.GTQ,
  Currency.GNF,
  Currency.GYD,
  Currency.HTG,
  Currency.HNL,
  Currency.HKD,
  Currency.HUF,
  Currency.ISK,
  Currency.INR,
  Currency.IDR,
  Currency.IRR,
  Currency.IQD,
  Currency.ILS,
  Currency.ITL,
  Currency.JMD,
  Currency.JPY,
  Currency.JOD,
  Currency.KZT,
  Currency.KES,
  Currency.KWD,
  Currency.KGS,
  Currency.LAK,
  Currency.LVL,
  Currency.LBP,
  Currency.LSL,
  Currency.LRD,
  Currency.LYD,
  Currency.LTL,
  Currency.MOP,
  Currency.MKD,
  Currency.MGA,
  Currency.MWK,
  Currency.MYR,
  Currency.MVR,
  Currency.MRO,
  Currency.MUR,
  Currency.MXN,
  Currency.MDL,
  Currency.MNT,
  Currency.MAD,
  Currency.MZM,
  Currency.MMK,
  Currency.NAD,
  Currency.NPR,
  Currency.ANG,
  Currency.TWD,
  Currency.NZD,
  Currency.NIO,
  Currency.NGN,
  Currency.KPW,
  Currency.NOK,
  Currency.OMR,
  Currency.PKR,
  Currency.PAB,
  Currency.PGK,
  Currency.PYG,
  Currency.PEN,
  Currency.PHP,
  Currency.PLN,
  Currency.QAR,
  Currency.RON,
  Currency.RUB,
  Currency.RWF,
  Currency.SVC,
  Currency.WST,
  Currency.SAR,
  Currency.RSD,
  Currency.SCR,
  Currency.SLL,
  Currency.SGD,
  Currency.SKK,
  Currency.SBD,
  Currency.SOS,
  Currency.ZAR,
  Currency.KRW,
  Currency.XDR,
  Currency.LKR,
  Currency.SHP,
  Currency.SDG,
  Currency.SRD,
  Currency.SZL,
  Currency.SEK,
  Currency.CHF,
  Currency.SYP,
  Currency.STD,
  Currency.TJS,
  Currency.TZS,
  Currency.THB,
  Currency.TOP,
  Currency.TTD,
  Currency.TND,
  Currency.TRY,
  Currency.TMT,
  Currency.UGX,
  Currency.UAH,
  Currency.AED,
  Currency.UYU,
  Currency.USD,
  Currency.UZS,
  Currency.VUV,
  Currency.VEF,
  Currency.VND,
  Currency.YER,
  Currency.ZMK,
]

export enum DateRange {
  ytd = 'YTD',
  ltm = '12M',
  all = 'ALL DATA',
  custom = 'CUSTOM'
}

// This is due to the different plurality in
// the filter designs throughout Dashboard
export enum FilterType {
  service = 'Service',
  services = 'Services',
  account = 'Account',
  accounts = 'Accounts',
  subaccount = 'Subaccount',
  subaccounts = 'Subaccounts',
  royalty = 'Royalty',
  clients = 'Clients',
  source = 'Source',
  project = 'Project',
  country = 'Country',
  name = 'Name'
}

export const filterTypesBreakdowns = [
  FilterType.service,
  FilterType.account,
  FilterType.subaccount,
  FilterType.royalty,
  FilterType.source,
  FilterType.project,
  FilterType.country,
]

export const fitlerTypesSensitive = [
  FilterType.clients,
  FilterType.account,
  FilterType.accounts,
  FilterType.subaccount,
  FilterType.subaccounts,
  FilterType.project,
]

export const filterTypesStatements = [
  FilterType.clients,
  FilterType.services,
  FilterType.accounts,
  FilterType.name
]

export const emptyBreakdowns: Breakdown[] = [
  { __typename: 'Breakdown', title: 'Services', rows: [] },
  { __typename: 'Breakdown', title: 'Royalties', rows: [] },
  { __typename: 'Breakdown', title: 'Top Sources', rows: [] },
  { __typename: 'Breakdown', title: 'Top Projects', rows: [] },
  { __typename: 'Breakdown', title: 'Top Countries', rows: [] },
]

export type NormalizedDownload = {
  __typename?: 'NormalizedDownload';
  isExporting: boolean,
  shouldSkipDownload: boolean,
  shouldSkipDownloadPercentageQuery: boolean,
  downloadTime: string,
  downloadId: string,
  downloadRoyaltyFilters: {
    lastCalled: RoyaltyFilters,
    current: RoyaltyFilters
  };
  downloadPercentage: number,
  downloadClientTitle: string,
  selectedFilters: string,
};

export const defaultNormalizedDownloadState: NormalizedDownload = {
  isExporting: false,
  shouldSkipDownload: true,
  shouldSkipDownloadPercentageQuery: true,
  downloadTime: '',
  downloadId: '',
  downloadRoyaltyFilters: {
    lastCalled: {},
    current: {}
  },
  downloadPercentage: 0,
  downloadClientTitle: '',
  selectedFilters: ''
}
