export const strings = {
  fileUploads: 'STATEMENT UPLOAD',
  manageServices: 'MANAGE SERVICES',
  logout: 'LOG OUT'
}

export const getParsedJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch {
    return undefined
  }
}
