import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import { colors } from '../../../global/colors'

const useStyles = makeStyles(() =>
  createStyles({
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    '@keyframes slideDown': {
      '0%': {
        transform: 'translateY(-100%)',
      },
      '100%': {
        transform: 'translateY(0)',
      },
    },
    '@keyframes fadeOut': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    '@keyframes slideOut': {
      '0%': {
        transform: 'translateY(0)',
      },
      '100%': {
        transform: 'translateY(-100%)',
      },
    },
    boxRootFadeIn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '50px',
      height: '50px',
      background: '#0069E3',
      animation: '$fadeIn 1s, $slideDown 1s',
    },
    boxRootFadeOut: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '50px',
      height: '50px',
      background: '#0069E3',
      animation: '$fadeOut 1s, $slideOut 1s',
    },
    text: {
      color: colors.white,
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.08em',
    },
  }))

interface TextBoxProps {
  text: string
  isFadeOut: boolean
}

export function TextBox({ text, isFadeOut }: TextBoxProps) {
  const classes = useStyles()

  return (
    <Box className={`${isFadeOut ? classes.boxRootFadeOut : classes.boxRootFadeIn}`}>
      <Box className={classes.text}>{text}</Box>
    </Box>
  )
}
