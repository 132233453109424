import {
  AppBar, Button, Box, IconButton, InputBase, Popover, Typography, TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useHistory, useLocation } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import {
  useEffect, useMemo, useRef, useState
} from 'react'

import { AccountMenu } from './AccountMenu'

import { Assets } from '../../../assets'
import { colors } from '../../../global/colors'
import { CONTACT_EMAIL, Routes } from '../../constants'
import { useClients } from '../../../domain/dashboards/hooks'
import { Dashboard } from '../../../domain/dashboards/types'
import { Redactable } from '../containers/Redactable'
import { useFeatureFlags, useFeatureFlagToggles } from '../../../domain/admin/flags/hooks'
import { handleClickOutside } from '../../../global/click'

const useStyles = makeStyles(() => createStyles({
  headerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerButtonPadded: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 40
  },
  headerOptions: {
    fontSize: 12,
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    minHeight: 35,
    display: 'flex',
    flex: 1,
    color: 'inherit',
    maxWidth: '30vw',
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
  },
  searchResult: {
    fontSize: 12,
    color: colors.grey500,
    textTransform: 'uppercase',
    paddingBottom: 8,
  },
  text: {
    color: colors.white,
    fontSize: 12,
  }
}))

export function Header() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [{
    demoMode, adminMode, isAdmin
  }] = useFeatureFlags()
  const { toggleAdminMode } = useFeatureFlagToggles()

  const clients = useClients()

  const clicks = useRef(0)
  const [hovered, setHovered] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchText, setSearchText] = useState('')

  const anchorTemp = useRef<HTMLDivElement>(null)

  const handleSetSearchFalse = () => {
    setSearching(false)
  }

  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => handleClickOutside(event, handleSetSearchFalse, anchorTemp, true)
    document.addEventListener('mousedown', onClickOutside)
    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  })

  const onHelp = async (eventName: string) => {
    window.location.href = `mailto:${CONTACT_EMAIL}?subject=${eventName}`
  }

  const onOpenSearch = () => {
    setSearchText('')
    setSearching(true)
  }

  const onCloseSearch = () => {
    setSearchText('')
    setSearching(false)
  }

  const onSearch = (text: string) => {
    setSearchText(text)
  }

  const onNavigateToClient = (client: Dashboard) => {
    history.push(`/clients/${client.id}`)
    onCloseSearch()
  }

  const currentRoute = location.pathname

  const clientsFiltered = useMemo(() => {
    if (!searchText.length) {
      return []
    }

    const filteredClients = clients.filter(
      (client) => client.title.toLowerCase().includes(searchText.toLowerCase())
    )

    filteredClients.sort((a, b) => a.title.localeCompare(b.title))
    return filteredClients
  }, [searchText, clients])

  const onToggleAdminMode = async () => {
    // only admins can toggle admin mode
    if (!isAdmin) return

    const { current } = clicks

    if (current < 2) {
      clicks.current += 1
    } else {
      clicks.current = 0
      toggleAdminMode()
    }
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.black,
        height: 50,
      }}>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          minWidth: 96,
          justifyContent: 'center',
          alignItems: 'center',
          WebkitTapHighlightColor: 'transparent'
        }}
        data-ph-capture-attribute-details={'Clicked [HIFI Logo]'}
        onClick={onToggleAdminMode}>
        <img data-testid='hifi-logo' alt="HIFI Logo" src={adminMode ? Assets.hifiLogoBlue : Assets.hifiLogoWhite} style={{ height: 16.5, width: 48, paddingLeft: 20 }} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" border={1}>
          <span style={{
            fontSize: 8, paddingRight: 4, paddingLeft: 4, paddingTop: 2.5, paddingBottom: 2.5
          }}>
            PREVIEW
          </span>
        </Box>
      </Box>
      <Box
        display="flex"
        style={{
          flexDirection: 'row',
          justifyContent: searching ? 'center' : 'flex-end',
          alignItems: 'center',
          flexGrow: 1,
          paddingRight: searching ? 120 : 20, // HACK: to center without absoluting the HIFI logo
        }}>

        <Box
          ref={anchorTemp}
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingRight: 40,
            minWidth: searching ? 600 : undefined,
            backgroundColor: searching ? colors.grey700 : undefined,
          }}>
          <div className={classes.searchIcon}>
            <IconButton
              size="large"
              style={{ borderRadius: 0 }}
              data-ph-capture-attribute-details={'Clicked [SearchIcon]'}
              onClick={() => (!searching ? onOpenSearch() : onCloseSearch())}>
              <SearchIcon style={{ color: colors.white, fontSize: 16 }} />
            </IconButton>
          </div>
          {!searching ? undefined : (
            <InputBase
              data-ph-capture-attribute-details={'SEARCH FOR A CLIENT'}
              onChange={(event) => onSearch(event.target.value)}
              type={demoMode ? 'password' : undefined}
              placeholder="SEARCH FOR A CLIENT"
              inputProps={{ 'aria-label': 'search' }}
              classes={{
                root: classes.inputRoot,
                input: classes.searchInput,
              }} />
          )}
          <Popover
            disableAutoFocus
            open={!!clientsFiltered.length && searching}
            anchorEl={anchorTemp.current}
            onClose={() => onCloseSearch()}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <Box style={{
              backgroundColor: colors.black, padding: 8, minWidth: 624,
            }}>

              {clientsFiltered
                .slice(0, clientsFiltered.length > 5 ? 5 : clientsFiltered.length)
                .map((client) => (
                  <TableRow
                    key={client.id}
                    hover
                    style={{ cursor: 'pointer' }}
                    data-ph-capture-attribute-details={`Clicked client [${client.title}]`}
                    onClick={() => onNavigateToClient(client)}
                    onMouseEnter={() => setHovered(client.id)}
                    onMouseLeave={() => setHovered('')}>
                    <Box key={client.title}>
                      <Typography
                        className={classes.searchResult}
                        style={{ color: hovered === client.id && !demoMode ? colors.pink500 : colors.white }}>
                        <Redactable inverted>
                          {client.title}
                        </Redactable>
                      </Typography>
                    </Box>
                  </TableRow>
                ))}
            </Box>
          </Popover>
        </Box>

        {searching || (
          <Box className={classes.headerButtonPadded}>
            <Button
              data-ph-capture-attribute-details={'Clicked navigation [ALL CLIENTS]'}
              disableElevation onClick={() => history.push(Routes.clients)}>
              <p style={{ fontSize: 12, color: currentRoute === Routes.clients ? colors.pink500 : colors.white }}>
                ALL CLIENTS
              </p>
            </Button>
          </Box>
        )}
        {searching || (
          <Box className={classes.headerButtonPadded}>
            <Button
              data-ph-capture-attribute-details={'Clicked navigation [ALL STATEMENTS]'}
              disableElevation onClick={() => history.push(Routes.statements)}>
              <p style={{ fontSize: 12, color: currentRoute === Routes.statements ? colors.pink500 : colors.white }}>
                ALL STATEMENTS
              </p>
            </Button>
          </Box>
        )}
        {searching || (
          <Box className={classes.headerButtonPadded}>
            <Button disableElevation data-ph-capture-attribute-details={'Clicked [HELP]'} onClick={() => onHelp('Enterprise - Help')}>
              <p style={{ fontSize: 12, color: colors.white }}>
                HELP
              </p>
            </Button>
          </Box>
        )}
        {searching || (
          <Box className={classes.headerButton}>
            <AccountMenu />
          </Box>
        )}
      </Box>
    </AppBar>
  )
}
