import {
  AppBar, Button, Box
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import { Assets } from '../../../assets'
import { colors } from '../../../global/colors'
import { useLogOut } from '../../../domain/auth/hooks'

const useStyles = makeStyles(() => createStyles({
  headerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.08em',
  }
}))

export function HeaderSimple() {
  const classes = useStyles()
  const logout = useLogOut()

  return (
    <AppBar
      position="fixed"
      elevation={0}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.black,
        height: 50,
      }}>
      <Box display="flex" flexDirection="column" style={{ minWidth: 96, justifyContent: 'center', alignItems: 'center' }}>
        <img data-testid='hifi-logo' alt="HIFI Logo" src={Assets.hifiLogoWhite} style={{ height: 16.5, width: 48, paddingLeft: 20 }} />
      </Box>
      <Box
        display="flex"
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexGrow: 1,
          paddingRight: 20,
        }}>
        
        <Box className={classes.headerButton}>
          <Button
            disableElevation
            data-ph-capture-attribute-details={'Clicked [LOGOUT]'}
            onClick={() => logout()}>
            <p className={classes.text} data-testid='log-out'>
              LOGOUT
            </p>
          </Button>
        </Box>
      </Box>
    </AppBar>
  )
}
