import { ApolloClient } from '@apollo/client'

import { User } from './types'
import { USER_DETAILS_QUERY } from './queries'

import { LoadingScreenQuery } from '../types'
import { addError } from '../../global/errors'

export const fetchUserDetails = async (apolloClient: ApolloClient<object>) => {
  const { data, errors } = await apolloClient.query<LoadingScreenQuery>({
    query: USER_DETAILS_QUERY,
  })

  if (errors) {
    const error = JSON.stringify(errors)
    addError(new Error('[fetchUserDetails]'), { errors: error })
    return undefined
  }

  const { suggestions, user } = data
  const { onboarded = false, allowlisted = false, fileUploadsEnabled } = user ?? {}

  return {
    onboarded,
    allowlisted,
    suggestions,
    fileUploadsEnabled,
    hasSession: true,
    initialized: true,
  } as User
}
