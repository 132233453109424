import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const { NODE_ENV, REACT_APP_ENV } = process.env

const initSentry = () => {
  Sentry.init({
    dsn: 'https://7ba17c3836278c9f3d880c26385348b2@o160250.ingest.sentry.io/4506241889796096',
    enabled: NODE_ENV === 'production',
    environment: REACT_APP_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

export { initSentry }

