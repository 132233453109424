import { useContext } from 'react'

import { FeatureFlags } from './types'

import { AdminContext } from '../provider'

// eslint-disable-next-line no-unused-vars
export function useFeatureFlags(): [FeatureFlags, (flags: FeatureFlags) => void] {
  const { featureFlags } = useContext(AdminContext)

  return featureFlags
}

export function useFeatureFlagToggles(): any {
  const {
    toggleAdminMode,
    toggleDemoMode,
  } = useContext(AdminContext)

  return {
    toggleAdminMode,
    toggleDemoMode,
  }
}
