import { ApolloProvider } from '@apollo/client'
import {
  createTheme, ThemeProvider, Theme, StyledEngineProvider
} from '@mui/material'
import GlobalStyles from '@mui/material/GlobalStyles'

import { RootRouter } from './router'

import packageJson from '../../package.json'
import { DashboardProvider } from '../domain/dashboards/provider'
import { createApolloClient } from '../global/apollo/apollo'
import { colors } from '../global/colors'
import { UserProvider } from '../domain/auth/provider'
import { getAccessToken, setAccessToken } from '../domain/auth/storage'
import { RoyaltyProvider } from '../domain/royalties/provider'
import { AdminProvider } from '../domain/admin/provider'
import { AnalyticsProvider } from '../domain/analytics/provider'
import { API_PORT, API_URL } from '../global/envs'
import { initConsole } from '../global/logger'
import './styles.css'
import 'react-notifications-component/dist/theme.css'

console.info('[version]', packageJson.version)

declare global {
  interface Window { analytics: any; }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

initConsole()

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const apolloClient = createApolloClient(
  API_URL,
  API_PORT,
  API_PORT === '443' || API_PORT === '8443', // is https
  getAccessToken,
  setAccessToken
)

const theme = createTheme({
  palette: {
    primary: {
      main: colors.pink500 // TODO: override button main color from primary for text??
    },
    secondary: {
      main: colors.blue500,
    },
    error: {
      main: colors.pink500,
    }
  },
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
})

export function App() {
  // force update
  return (
    <ApolloProvider client={apolloClient}>
      <AnalyticsProvider>
        <UserProvider>
          <AdminProvider>
            <DashboardProvider>
              <RoyaltyProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <GlobalStyles styles={{
                      '.enterprise-date-picker + .MuiPickersPopper-root': {
                        '& .MuiPickersYear-yearButton.Mui-selected': {
                          '&:hover, &:focus': {
                            backgroundColor: colors.pink500
                          },
                        },
                        '& .MuiPickersMonth-monthButton.Mui-selected': {
                          '&:hover, &:focus': {
                            backgroundColor: colors.pink500
                          },
                        },
                      },
                      '.enterprise-date-picker': {
                        '& .enterprise-date-picker-startDate.focused fieldset': {
                          borderColor: colors.pink500,
                          borderWidth: '2px'
                        },
                        '& .enterprise-date-picker-startDate.not-focused fieldset': {
                          borderColor: colors.grey300,
                          borderWidth: '2px'
                        },
                        '& .enterprise-date-picker-endDate.focused fieldset': {
                          borderColor: colors.pink500,
                          borderWidth: '2px'
                        },
                        '& .enterprise-date-picker-endDate.not-focused fieldset': {
                          borderColor: colors.grey300,
                          borderWidth: '2px'
                        }
                      }
                    }} />
                    <RootRouter />
                  </ThemeProvider>
                </StyledEngineProvider>
              </RoyaltyProvider>
            </DashboardProvider>
          </AdminProvider>
        </UserProvider>
      </AnalyticsProvider>
    </ApolloProvider>
  )
}
