import * as Sentry from '@sentry/react'
import { GraphQLError } from 'graphql'

type ExtraInfo = Record<string, string>

const UNKNOWN = 'unknown'

const sendSentryEvent = (
  error: Error,
  extraInfo: ExtraInfo = {},
  level: Sentry.Severity = Sentry.Severity.Error,
  userId: string = UNKNOWN,
): void => {
  console.error(error)

  Sentry.withScope((scope) => {
    scope.setLevel(level)
    scope.setExtras({
      ...extraInfo,
      errors: JSON.stringify(error)
    })
    scope.setUser({ id: userId })

    Sentry.captureException(error)
  })
}

export const addError = (
  error: Error,
  extraInfo: ExtraInfo = {},
  userId: string = UNKNOWN,
): void => {
  return sendSentryEvent(error, extraInfo, Sentry.Severity.Error, userId)
}

export const addInfo = (
  error: Error,
  extraInfo: ExtraInfo = {},
  userId: string = UNKNOWN
): void => {
  // eslint-disable-next-line no-console
  console.info(error)
  return sendSentryEvent(error, extraInfo, Sentry.Severity.Info, userId)
}

export function formatError(errors: readonly GraphQLError[] = []): String {
  const parsedErrors = errors.map((gqlError) => {
    try {
      return JSON.stringify(gqlError)
    } catch (e) {
      return 'N/A'
    }
  })

  return parsedErrors.join(' ')
}

// Product API error codes
export const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS'
export const USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST'
