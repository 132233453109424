
import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './ui/app'
import { initSentry } from './global/libs/sentry/sentry'
import { initPosthog } from './global/libs/posthog'

initSentry()

initPosthog()

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
