import { gql } from '@apollo/client'

export const QUERY_DASHBOARD = gql`
  query Dashboard($id: ID!) {
    dashboard(id: $id) { 
      id
      title
      userId 
      vendorAccountIds
      vendorSubaccountIds
    }
  }
`

export const QUERY_DASHBOARDS = gql`
  query Dashboards {
    dashboards { 
      id
      title
      userId 
      vendorAccountIds
      vendorSubaccountIds
      hidden
    }
  }
`

export const DELETE_DASHBOARD_MUTATION = gql`
  mutation DeleteDashboard($id: ID!) {
    deleteDashboard(id: $id) {
      status
      message
    }
  }
`

export const CREATE_DASHBOARD_MUTATION = gql`
  mutation CreateDashboard($dashboard: DashboardInput!) {
    createDashboard(dashboard: $dashboard) { 
      id
      title
      userId  
      vendorAccountIds
      vendorSubaccountIds
    }
  }
`

export const UPDATE_DASHBOARD_MUTATION = gql`
  mutation UpdateDashboard($id: ID!, $dashboard: DashboardInput!) {
    updateDashboard(id: $id, dashboard: $dashboard) { 
      status
      message
    }
  }
`
