import { posthog } from 'posthog-js'

const initPosthog = () => {
  posthog.init('phc_4bNfQ4YGJaHktW1JfQHkbsbF9Oy8mMCupGPoLFwEt85', {
    api_host: 'https://app.posthog.com',
    enable_recording_console_log: true,
  })

}

export { initPosthog }
