/* eslint-disable no-unused-vars */

import { VendorSlug } from '@rcrdclub/common-front/lib/types'

export type AuthTokens = {
  accessToken: string
  refreshToken: string
}

export type AuthSession = {
  userId: String
  accountId: String
  tokens: AuthTokens
}

export type AuthResponse = {
  status: String
  session: AuthSession
}

export type User = {
  hasSession: boolean
  initialized: boolean
  services: VendorSlug[]
  suggestions: string[]
  onboarded: boolean,
  allowlisted: boolean
  fileUploadsEnabled: boolean,
}

export interface Auth {
  userId?: string
  accessToken?: string
}

export type Verification = {
  phoneNumber: string
  verificationId: string
}

export const defaultUserState: User = {
  services: [],
  suggestions: [],
  hasSession: false,
  allowlisted: false,
  onboarded: false,
  initialized: false,
  fileUploadsEnabled: false,
}

export const defaultAuthState: Auth = {
  userId: undefined,
  accessToken: undefined
}

export const defaultVerificationState: Verification = {
  verificationId: '',
  phoneNumber: ''
}

// Stores
export type AuthState = [
  Auth,
  (session: Auth) => void
]

export type UserState = [
  User,
  (user: User) => void
]

export type VerificationState = [
  Verification,
  (auth: Verification) => void
]
