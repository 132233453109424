/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-multi-assign */
import { v4 as uuid } from 'uuid'

import { AnalyticsClient, TrackableAction } from './types'

import { SEGMENT_KEY } from '../../global/envs'
import { Routes } from '../../ui/constants'
import { getAccessToken } from '../auth/storage'

const KEY_ANONYMOUS_ID = 'segment-anonymous-id'
const ANONYMOUS_ID_PREFIX = 'enterprise-anon'

function initSegment(segmentKey: string) {
  // Taken from https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
  // NOTE: using a JS snippet is recommended over the npm lib

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const analytics = window.analytics = window.analytics || []; if (!analytics.initialize) {
    if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.'); else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      analytics.invoked = !0
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware']; analytics.factory = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics
        }
      }; for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e]; analytics[key] = analytics.factory(key)
      } analytics.load = function (key: any, e: any) {
        const t = document.createElement('script'); t.type = 'text/javascript'; t.async = !0; t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`; const n = document.getElementsByTagName('script')[0]; n.parentNode?.insertBefore(t, n); analytics._loadOptions = e
      }; analytics._writeKey = SEGMENT_KEY; analytics.SNIPPET_VERSION = '4.13.2'
      analytics.load(segmentKey)
    }
  }
}

export default class SegmentClient implements AnalyticsClient {
  private readonly enabled: boolean = true

  constructor(segmentKey: string, enabled: boolean) {
    this.enabled = enabled

    if (!enabled) return

    initSegment(segmentKey)
  }

  private getAnonymousId = () => {
    const id = localStorage.getItem(KEY_ANONYMOUS_ID)
    if (id) return id

    const newId = `${ANONYMOUS_ID_PREFIX}-${uuid()}`
    localStorage.setItem(KEY_ANONYMOUS_ID, newId)

    return newId
  }

  // https://segment.com/docs/guides/how-to-guides/best-practices-identify/#anonymous-history-is-lost
  async identify(userId: string, traits = {}): Promise<boolean> {
    if (!this.enabled) return false

    // alias old events to new user id
    const previousId = this.getAnonymousId()
    await analytics.alias(userId, previousId)

    // identify user with new id
    await analytics.identify(userId, traits)

    return true
  }

  async page(route: string, properties?: object): Promise<boolean> {
    if (!this.enabled) return false

    let canonicalRoute = route

    // special case for `/`
    if (route === Routes.intro) {
      const token = getAccessToken()
      canonicalRoute = token ? Routes.clients : Routes.login
    }

    // resolve to a human readable name
    let name = canonicalRoute

    try {
      name = canonicalRoute as Routes
    } catch (error) {
      console.warn('Could not find name for route')
    }

    await analytics.page(name ?? canonicalRoute, properties)

    return true
  }

  async track(event: TrackableAction, properties = {}): Promise<boolean> {
    if (!this.enabled) return false
    await analytics.track(event, properties)
    return true
  }
}
