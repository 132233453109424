import { IS_PRODUCTION } from '../ui/constants'

export function initConsole() {
  const logDebug = console.debug.bind(console)
  const logWarn = console.warn.bind(console)
  const logInfo = console.info.bind(console)
  const logError = console.error.bind(console)

  console.debug = function (...anything) {
    if (!IS_PRODUCTION) {
      logDebug.apply(this, anything)
    }
  }

  console.info = function (...anything) {
    if (!IS_PRODUCTION) {
      logInfo.apply(this, anything)
    }
  }

  console.warn = function (...anything) {
    logWarn.apply(this, anything)
  }

  console.error = function (...anything) {
    logError.apply(this, anything)
  }
}
