/* eslint-disable no-unused-vars */
import { createContext, useEffect, useState } from 'react'

import { defaultFeatureFlags, FeatureFlags } from './flags/types'
import { CUSTOM_CLAIMS_QUERY } from './queries'
import { loadDemoMode, saveDemoMode } from './storage'

import { CustomClaimsQuery } from '../types'
import { apolloClient } from '../../global/apollo/apollo'
import { useSession } from '../auth/hooks'
import { addError } from '../../global/errors'

export type AdminState = {
  featureFlags: [FeatureFlags, (flags: FeatureFlags) => void],
  toggleAdminMode: () => void,
  toggleDemoMode: (enabled?: boolean) => void,
}

export const AdminContext = createContext<AdminState>({
  featureFlags: [defaultFeatureFlags, () => undefined],
  toggleAdminMode: () => ({}),
  toggleDemoMode: () => ({}),
})

export const AdminProvider = ({ children }: any) => {
  const [{ userId }] = useSession()
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags)

  const toggleAdminMode = () => {
    const flags = {
      ...featureFlags,
      adminMode: !featureFlags.adminMode
    }

    setFeatureFlags(flags)
  }

  const toggleDemoMode = (enabled?: boolean) => {
    const flags = {
      ...featureFlags,
      demoMode: enabled ?? !featureFlags.demoMode
    }
    saveDemoMode(flags.demoMode)
    setFeatureFlags(flags)
  }

  const store: AdminState = {
    featureFlags: [featureFlags, setFeatureFlags],
    toggleAdminMode,
    toggleDemoMode,
  }

  // fetch feature flags on page load
  useEffect(() => {
    const onLoadFeatureFlags = async () => {
      try {
        if (userId) {
          const { data } = await apolloClient.query<CustomClaimsQuery>({
            query: CUSTOM_CLAIMS_QUERY,
          })
          const demoMode = await loadDemoMode()
          const {
            hasEnterprise, hasBulkCreds, hasNormalizedDownloads, isAdmin,
            userHash
          } = data?.customClaims ?? {}
    
          setFeatureFlags({
            ...featureFlags,
            enterpriseAccess: hasEnterprise,
            bulkCredsAccess: hasBulkCreds,
            normalizedDownloadsAccess: hasNormalizedDownloads,
            adminMode: isAdmin,
            demoMode,
            isAdmin,
            userHash
          })

        }
      } catch (error: any) {
        addError(error)
      }
      
    }

    onLoadFeatureFlags()
  }, [userId])

  return (
    <AdminContext.Provider value={store}>
      {children}
    </AdminContext.Provider>
  )
}
