import { gql } from '@apollo/client'

export const SAVE_STATEMENTS_NORMALIZED_MUTATION = gql`
  mutation SaveStatementsNormalized($filters: RoyaltyFilters!, $filterTags: String) {
    saveStatementsNormalized(filters: $filters, filterTags: $filterTags){
      status
      message
      downloadId
    }
  }
`

export const STATEMENTS_NORMALIZED_URL_QUERY = gql` 
  query StatementsNormalizedUrl($filters: RoyaltyFilters!, $timeStamp: String) {
    statementsNormalizedUrl(filters: $filters, timeStamp: $timeStamp)
  }
`

export const DOWNLOADING_NORMALIZED_PERCENTAGE_QUERY = gql` 
  query DownloadingNormalizedPercentage($downloadId: String) {
    downloadingNormalizedPercentage(downloadId: $downloadId)
  }
`

export const NORMALIZED_DOWNLOADS_HISTORY_QUERY = gql` 
  query NormalizedDownloadsHistory($dashboardId: String) {
    normalizedDownloadsHistory(dashboardId: $dashboardId) {
      id 
      userId
      downloadId
      gcsFileName
      queryParameters {
        jobIds
        filters {
          currency
          dashboardIds
          dateType
          endDate
          period
          startDate
          values {
            value
            type
          }
          vendorAccountIds
          vendorSlugs
          vendorSubaccountIds
        }
      }
      filterTags
      useCurrency
      updatedAt
      activityStatus
    }
  }
`

export const STATEMENTS_NORMALIZED_COUNT_QUERY = gql` 
  query StatementsNormalizedCount($filters: RoyaltyFilters!) {
    statementsNormalizedCount(filters: $filters)
  }
`
