import { useFeatureFlags } from '../../../domain/admin/flags/hooks'
import { colors } from '../../../global/colors'

export function Redactable({
  children, disabled = false, inverted = false, style, ...other
}: any) {
  const [{ demoMode }] = useFeatureFlags()

  return (
    disabled || !demoMode ? children
      : (
        <span
          style={!demoMode ? null : {
            backgroundColor: inverted ? colors.white : colors.black,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            ...style
          }} {...other}>
          {children}
        </span>
      )
  )
}
