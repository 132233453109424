import { Dashboard, DEFAULT_DASHBOARD } from './types'

//  Keys may contain alphanumeric characters `.`, `-`, and `_`.

export const DASHBOARD_TOKEN_KEY = 'dashboards'
export const CURRENT_ID_KEY = 'currentId'
export const CURRENT_USER_ID_KEY = 'current-user-id'
export const CURRENT_GROUP_ID_KEY = 'current-groups'

export function loadDashboards(): Promise<Dashboard[]> {
  return JSON.parse(localStorage.getItem(DASHBOARD_TOKEN_KEY) || '[]')
}

export function saveDashboards(dashboards: Dashboard[]): void {
  localStorage.setItem(DASHBOARD_TOKEN_KEY, JSON.stringify(dashboards))
}

export function saveCurrent(dashboardId: string): void {
  localStorage.setItem(CURRENT_ID_KEY, dashboardId)
}

export function deleteDashboardsAll(): void {
  localStorage.removeItem(DASHBOARD_TOKEN_KEY)
}
export function loadCurrent(): string {
  return localStorage.getItem(CURRENT_ID_KEY) || DEFAULT_DASHBOARD
}
