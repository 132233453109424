/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
// TODO: remove this, again
const {
  REACT_APP_ENV,
  REACT_APP_API,
  REACT_APP_API_PORT,
  REACT_APP_SEGMENT_KEY
} = process.env

export const ENV = REACT_APP_ENV
export const API_URL = REACT_APP_API ?? 'localhost'
export const API_PORT = REACT_APP_API_PORT || '443'
export const API_URL_FULL = `http${API_PORT === '443' ? 's' : ''}://${API_URL}:${API_PORT}`
export const SEGMENT_KEY = REACT_APP_SEGMENT_KEY

export enum Envs {
  Production = 'prod',
  Development = 'dev',
  Local = 'local',
}
