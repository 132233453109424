import {
  useContext, useCallback, useEffect, useRef,
} from 'react'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import {
  defaultUserState, UserState, AuthState, defaultAuthState, VerificationState, Auth
} from './types'
import { destroyUserSession, getAccessToken, saveAuth } from './storage'
import { VerificationContext, AuthContext, UserContext } from './provider'
import { REFRESH_QUERY } from './queries'

import { CUSTOM_CLAIMS_QUERY } from '../admin/queries'
import { CustomClaimsQuery, defaultNormalizedDownloadState } from '../types'
import useAnalytics from '../analytics/useAnalytics'
import { defaultFeatureFlags } from '../admin/flags/types'
import { useFeatureFlags } from '../admin/flags/hooks'
import { getParsedJwt } from '../../global/strings'
import { useNormalizedDownload } from '../royalties/hooks'

export function useSession(): AuthState {
  const [session, setSession] = useContext(AuthContext)

  return [session, setSession]
}

export function useUserDetails(): UserState {
  const [userDetails, setUserDetails] = useContext(UserContext)

  return [userDetails, setUserDetails]
}

export function useVerificationState(): VerificationState {
  const [verificationState, setVerificationState] = useContext(VerificationContext)

  return [verificationState, setVerificationState]
}

/**
 * Use Log Out
 */
export function useLogOut(): Function {
  const history = useHistory()

  const [, setUserSession] = useSession()
  const [, setUserState] = useUserDetails()
  const [, setFeatureFlags] = useFeatureFlags()
  const [, setNormalizedDownload] = useNormalizedDownload()

  const apolloClient = useApolloClient()
  const analytics = useAnalytics()

  return useCallback(async () => {
    const onClearStore = async () => {
      await setFeatureFlags(defaultFeatureFlags)

      // Clear/Stop Apollo
      await apolloClient.clearStore()

      // Clear User Data
      await destroyUserSession()

      // Reset user state to navigate to WelcomeScreen
      setUserSession(defaultAuthState)

      history.replace('/')
    }

    await onClearStore()

    // Reset user state to navigate to WelcomeScreen
    setUserState(defaultUserState)
    // Reset NormalizedDownload state when logout
    setNormalizedDownload(defaultNormalizedDownloadState)

    await analytics.track('auth.logout.success')
  }, [apolloClient, setUserSession, setUserState])
}

export function useLogIn() {
  const [, setUserSession] = useSession()
  const [, setFeatureFlags] = useFeatureFlags()

  const apolloClient = useApolloClient()
  const analytics = useAnalytics()

  return useCallback(async (userSession: Auth) => {
    await saveAuth(userSession)

    const { data } = await apolloClient.query<CustomClaimsQuery>({
      query: CUSTOM_CLAIMS_QUERY,
    })

    const {
      hasEnterprise = false,
      hasBulkCreds = false,
      hasNormalizedDownloads = false,
      isAdmin,
      userHash
    } = data.customClaims

    const flags = {
      demoMode: false,
      adminMode: isAdmin,
      isAdmin,
      enterpriseAccess: hasEnterprise,
      bulkCredsAccess: hasBulkCreds,
      normalizedDownloadsAccess: hasNormalizedDownloads,
      userHash
    }

    await setFeatureFlags(flags)

    const isAllowed = hasEnterprise || isAdmin

    if (!isAllowed) {
      saveAuth({})
      analytics.track('auth.login.failure')
    } else {
      setUserSession(userSession)
      await analytics.track('auth.login.success')
    }

    return {
      success: !!isAllowed,
      flags
    }
  }, [setUserSession])
}

const CHECK_INTERVAL = 60000 // 1 minute
const TIMEOUT_INTERVAL = 10 // 10 minutes
const TIMEOUT_INTERVAL_DEMO_MODE = 30 // 30 minutes when demo mode is active

/**
 * Use Activity Timeout
 *
 * Refreshes the users access tokens unless the user
 * has been idle for 10 minutes or more (30 minutes when demo mode is active)
 *
 * NOTE BELOW:
 * not necessary for "timeouts" to occur
 * can just rely on a longer refresh token expiration
 */
export function useActivityTimeout({ active, demoMode }: { active: boolean, demoMode: boolean }) {
  const logout = useLogOut()
  const idleTime = useRef(0) // in minutes
  const timer = useRef<any>(null)
  
  const events = [
    'load',
    'mousemove',
    'keypress',
    'mousedown',
    'click',
    'scroll',
  ]

  const onUserActivity = () => {
    console.info('[useActivityTimeout] activity logged at', idleTime.current)

    idleTime.current = 0

    Object.values(events).forEach((item) => {
      window.removeEventListener(item, onUserActivity, true)
    })
  }

  const [refreshToken] = useLazyQuery(REFRESH_QUERY)

  const onActivityCheck = useCallback(async () => {
    idleTime.current += 1

    const accessToken = getAccessToken()
    const parsedToken = accessToken && getParsedJwt(accessToken)
    const expires_at = parsedToken && parsedToken['https://stytch.com/session'].expires_at
    const expiresTime: any = new Date(expires_at)
    const nowTime: any = new Date()
    const tokenValidTime: number = expiresTime - nowTime

    const isFiveMultiply = idleTime.current > 0 && idleTime.current % 5 === 0
    const shouldReFresh = tokenValidTime < 60000 || isFiveMultiply

    if (shouldReFresh) {
      try {
        // ensures we get issued a new token with a longer expiry
        await refreshToken()
      } catch (error) {
        clearInterval(timer.current)
        return logout()
      }
    }

    console.info('[useActivityTimeout] idle check, time at', idleTime.current)

    // Kill all events
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, onUserActivity, true)
    })

    // force logout after 10 minutes of inactivity
    if (idleTime.current >= TIMEOUT_INTERVAL && !demoMode) {
      console.info(`[useActivityTimeout] idle for ${TIMEOUT_INTERVAL} min, logging out`, idleTime.current)
      idleTime.current = 0
      clearInterval(timer.current)
      return logout()
    }

    // force logout after 30 minutes of inactivity when demo mode is active
    if (idleTime.current >= TIMEOUT_INTERVAL_DEMO_MODE && demoMode) {
      console.info(`[useActivityTimeout] idle for ${TIMEOUT_INTERVAL_DEMO_MODE} min, logging out`, idleTime.current)
      idleTime.current = 0
      clearInterval(timer.current)
      return logout()
    }

    // add back if still goin'

    Object.values(events).forEach((item) => {
      window.addEventListener(item, onUserActivity, true)
    })
  }, [demoMode])

  useEffect(() => {
    return () => clearInterval(timer.current)
  }, [])

  useEffect(() => {
    if (!active) return
    console.info('[useActivityTimeout] initializing at', idleTime)

    if (timer.current) {
      clearInterval(timer.current)
    }

    // Increment the idle time counter every minute.
    timer.current = setInterval(onActivityCheck, CHECK_INTERVAL)
  }, [active, demoMode])
}
