import { useContext } from 'react'

import { AnalyticsContext } from './provider'
import { AnalyticsClient } from './types'

export default function useAnalytics(): AnalyticsClient {
  const context = useContext(AnalyticsContext)

  if (!context.client) {
    throw new Error(
      'Analytics context consumer invoked without provider'
    )
  }

  return context.client
}
