import { gql } from '@apollo/client'

export const CUSTOM_CLAIMS_QUERY = gql`
  query CustomClaims {
    customClaims {  
      isAdmin
      hasCustomDashboards
      hasEnterprise
      hasBulkCreds
      hasNormalizedDownloads
      userHash
    }
  }
`
