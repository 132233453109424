//  Keys may contain alphanumeric characters `.`, `-`, and `_`.

export const DEMO_MODE = 'demo-mode'
export const CUSTOM_CLAIMS_KEY = 'custom-claims'

export async function loadDemoMode(): Promise<boolean> {
  return await localStorage.getItem(DEMO_MODE) === '1'
}

export async function saveDemoMode(enabled: boolean): Promise<void> {
  await localStorage.setItem(DEMO_MODE, (enabled ? 1 : 0).toString())
}

export async function loadDashboardsEnabled(): Promise<number> {
  return Number.parseInt((await localStorage.getItem(CUSTOM_CLAIMS_KEY) || '0'), 10)
}

export async function saveDashboardsEnabled(enabled: number): Promise<void> {
  await localStorage.setItem(CUSTOM_CLAIMS_KEY, (enabled ? 1 : 0).toString())
}
