import { defaultAuthState, Auth } from './types'

import { addError } from '../../global/errors'

const ENV_KEY = process.env.REACT_APP_ENV ?? ''

//  Keys may contain alphanumeric characters `.`, `-`, and `_`.
export const ACCESS_TOKEN_KEY = `${ENV_KEY}session-token`
export const CURRENT_USER_ID_KEY = `${ENV_KEY}current-user-id`

export function getAccessToken(): string | undefined {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY) || ''
}

export function setAccessToken(token: string | undefined): void {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, token ? token : '')
}

export async function getCurrentUserId(): Promise<string | undefined> {
  return (await localStorage.getItem(CURRENT_USER_ID_KEY)) || undefined
}

export async function setCurrentUserId(id: string): Promise<void> {
  await localStorage.setItem(CURRENT_USER_ID_KEY, id)
}

export function destroyUserSession(): void {
  localStorage.clear()
  sessionStorage.clear()
}

export async function saveAuth(userSession: Auth): Promise<void> {
  await Promise.all([
    setCurrentUserId(userSession.userId ?? ''),
    setAccessToken(userSession.accessToken ?? '')
  ])
}

export async function loadAuth(): Promise<Auth> {
  try {
    const [userId, accessToken] = await Promise.all([
      getCurrentUserId(),
      getAccessToken()
    ])

    return {
      userId,
      accessToken
    }
  } catch (getError: any) {
    addError(getError)

    try {
      await destroyUserSession()
    } catch (destroyError: any) {
      addError(destroyError)
    }

    return defaultAuthState
  }
}
