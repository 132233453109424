import { createContext, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'

import {
  defaultUserState,
  defaultAuthState,
  Auth,
  AuthState,
  UserState,
  VerificationState,
  defaultVerificationState,
  Verification,
  User
} from './types'
import { loadAuth } from './storage'
import { fetchUserDetails } from './actions'

import useAnalytics from '../analytics/useAnalytics'
import { addError } from '../../global/errors'

export const AuthContext = createContext<AuthState>([
  defaultAuthState,
  () => undefined // will be overwritten when the Provider is initialized
])

export const UserContext = createContext<UserState>([
  defaultUserState,
  () => undefined // will be overwritten when the Provider is initialized
])

export const VerificationContext = createContext<VerificationState>([
  defaultVerificationState,
  () => undefined // will be overwritten when the Provider is initialized
])

/**
 * User Provider
 *
 * Manages both user session and state
 */
export const UserProvider = ({ children }: { children: any }) => {
  const analytics = useAnalytics()
  const apolloClient = useApolloClient()

  const authState = useState<Auth>(defaultAuthState)
  const userState = useState<User>(defaultUserState)
  const verificationState = useState<Verification>(defaultVerificationState)

  const [, setUser] = userState
  const [auth, setAuth] = authState

  // runs once when the app first initializes
  useEffect(() => {
    const onLoadUser = async () => {
      const { userId, accessToken } = await loadAuth()

      // Valid user session could not be loaded on app load
      if (!(userId && accessToken)) {
        setAuth(defaultAuthState)
        setUser({ ...defaultUserState, initialized: true })
        return
      }

      // Set session w/ getters
      setAuth({ userId, accessToken })
    }

    // If an error occurs, attempt to update the app
    // assuming we've changed how to handle user sessions / state
    try {
      onLoadUser()
    } catch (error: any) {
      addError(error)
    }
  }, [])

  // If updating session, first load or not, replace identity
  // in segment and update user state based on server synced data
  useEffect(() => {
    const onUpdateUserState = async () => {
      if (auth.userId) {
        await analytics.identify(auth.userId)

        try {
          const currentUserState = await fetchUserDetails(apolloClient) ?? defaultUserState
          setUser({ ...currentUserState, initialized: true })
        } catch (error) {
          console.error('Failed to pull current user state')
        }

      }
    }

    onUpdateUserState()
  }, [auth])

  return (
    <VerificationContext.Provider value={verificationState}>
      <AuthContext.Provider value={authState}>
        <UserContext.Provider value={userState}>
          {children}
        </UserContext.Provider>
      </AuthContext.Provider>
    </VerificationContext.Provider>
  )
}
