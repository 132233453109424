import { gql } from '@apollo/client'

export const LOG_IN_MUTATION = gql`
  mutation Login($phoneNumber: String!) {
    login(phoneNumber: $phoneNumber) { 
      id
      status 
    }
  }
`

export const SIGN_UP_MUTATION = gql`
  mutation Signup($phoneNumber: String!) {
    signup(phoneNumber: $phoneNumber) { 
      id
      status 
    }
  }
`

export const VERIFY_PIN_MUTATION = gql`
  mutation Verify($verificationId: ID!, $pin: String!) {
    verify(verificationId: $verificationId, pin: $pin) {
      userId
      accountId  
      jwt
      verification {
        id
        status
      }
    }
  }
`

export const RESEND_CODE_MUTATION = gql`
  mutation ResendCode($phoneNumber: String!) {
    resendCode(phoneNumber: $phoneNumber) { 
      id
      status
    }
  }
`

export const USER_DETAILS_QUERY = gql`
  query LoadingScreen {
    suggestions

    user {
      onboarded
      phoneNumber
      allowlisted
      fileUploadsEnabled
      notificationsEnabled
      memberApplicationSubmitted
      interests {
        cashflow
        membership
        soundexchange
      }
    }
}
`

export const REFRESH_QUERY = gql`
  query Refresh {
    refresh
  }
`
