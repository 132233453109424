import {
  useEffect, useContext, useCallback,

} from 'react'
import { QueryResult } from '@apollo/client'

import {
  InitializingState, LoadingState, OnBackState, RefreshState, SilentLoadingState
} from './types'
import { LoadingContext } from './provider'

import { addError } from '../../global/errors'

export function useGlobalLoader(): LoadingState {
  const { loading } = useContext(LoadingContext)
  return loading
}

export function useOnBack(): OnBackState {
  const { onBack } = useContext(LoadingContext)

  return onBack
}

export function useLoading(): SilentLoadingState {
  const { loadingSilent } = useContext(LoadingContext)

  return loadingSilent
}

export function useSetGlobalLoader(isLoading: boolean) {
  const { loading } = useContext(LoadingContext)
  const [globalLoader, setGlobalLoader] = loading

  useEffect(() => {
    setGlobalLoader(isLoading)

    return () => setGlobalLoader(false)
  }, [globalLoader, isLoading, setGlobalLoader])
}

export function useRefresh(queries: [QueryResult]): RefreshState {
  const { refreshing: refreshingContext } = useContext(LoadingContext)
  const [refreshing, setRefreshing] = refreshingContext

  const onRefresh = useCallback(() => {
    setRefreshing(true)

    async function refetchAllData() {
      try {
        await Promise.all(queries.map(async (query) => {
          await query.refetch()
        }))
      } catch (e: any) {
        addError(e)
      } finally {
        setRefreshing(false)
      }
    }

    refetchAllData()
  }, [setRefreshing, queries])

  return [refreshing, onRefresh]
}

export function useInitializing(): InitializingState {
  const { initializing } = useContext(LoadingContext)

  return initializing
}
