/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-function */

import {
  createContext,
} from 'react'

import SegmentClient from './model'
import { AnalyticsClient } from './types'

import { SEGMENT_KEY } from '../../global/envs'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const segmentClient = new SegmentClient(SEGMENT_KEY!, true)

export interface AnalyticsStore {
  client?: AnalyticsClient
}

export const AnalyticsContext = createContext<AnalyticsStore>({
  client: segmentClient
})

export const AnalyticsProvider = ({ children }: any) => {
  const store: AnalyticsStore = {
    client: segmentClient
  }

  return (
    <AnalyticsContext.Provider value={store}>
      {children}
    </AnalyticsContext.Provider>
  )
}
