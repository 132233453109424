/* eslint-disable no-empty-function */

import { v4 as uuid4 } from 'uuid'
import { useState, createContext } from 'react'

import { FilterBatch, FilterStore } from './types'

export const FilterContext = createContext<FilterStore>({
  current: [0, () => { }],
  filters: [[{ id: 'default', filters: [] }], () => { }],
  addFilter: () => { },
  removeFilter: () => { },
})

export const FilterProvider = ({ children }: { children: any }) => {
  const [current, setCurrent] = useState<number>(0)
  const [filters, setFilters] = useState<FilterBatch[]>([{ id: 'default', filters: [] }])

  const onSetFilters = (filter: FilterBatch) => {
    setFilters([filter])
  }

  const onAddFilter = (filter: FilterBatch) => {
    const filterNew = { ...filter, id: uuid4() }
    setFilters([...filters, filterNew])
    setCurrent(current + 1)
  }

  const onRemoveFilter = (filter: FilterBatch) => {
    const filtersNew = filters.filter((fil) => fil.id !== filter.id)
    setFilters(filtersNew)
  }

  const store: FilterStore = {
    current: [current, setCurrent],
    filters: [filters, onSetFilters],
    addFilter: onAddFilter,
    removeFilter: onRemoveFilter,
  }

  return (
    <FilterContext.Provider value={store}>
      {children}
    </FilterContext.Provider>
  )
}
