import './styles.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect
} from 'react-router-dom'
import { posthog } from 'posthog-js'
import {
  createTheme, ThemeProvider, Theme, StyledEngineProvider
} from '@mui/material'
import {
  useEffect, Suspense, lazy, useState
} from 'react'
import { ReactNotifications } from 'react-notifications-component'
import { ErrorBoundary } from 'react-error-boundary'

import { Header, HeaderSimple, HeaderIntro } from './_components/header'
import { TextBox } from './_components/informationBox'
import { Routes } from './constants'
import { LicenseScreen } from './LicenseScreen'

import { colors } from '../global/colors'
import useAnalytics from '../domain/analytics/useAnalytics'
import { useFeatureFlags } from '../domain/admin/flags/hooks'
import { useActivityTimeout, useSession, useUserDetails } from '../domain/auth/hooks'
import { CUSTOM_CLAIMS_QUERY } from '../domain/admin/queries'
import { apolloClient } from '../global/apollo/apollo'
import { CustomClaimsQuery } from '../domain/types'
import { FeatureFlags, defaultFeatureFlags } from '../domain/admin/flags/types'
import { addError } from '../global/errors'
import { useNormalizedDownload } from '../domain/royalties/hooks'

// NOTE: Meant to lazy load components to speed up initial load times
const LoginScreen = lazy(
  () => import('./Onboarding/LoginScreen').then((module: any) => ({ default: module.LoginScreen }))
)

const PinScreen = lazy(
  () => import('./Onboarding/PinScreen').then((module: any) => ({ default: module.PinScreen }))
)

const AllClientsScreen = lazy(
  () => import('./Clients/AllClientsScreen').then((module: any) => ({ default: module.AllClientsScreen }))
)

const ClientDashboardScreen = lazy(
  () => import('./Clients/ClientDashboard/ClientDashboardScreen').then((module: any) => ({ default: module.ClientDashboardScreen }))
)

const AllStatementsScreen = lazy(
  () => import('./Statements/AllStatementsScreen').then((module: any) => ({ default: module.AllStatementsScreen }))
)

const ManageServicesScreen = lazy(
  () => import('./Manage/ManageServicesScreen').then((module: any) => ({ default: module.ManageServicesScreen }))
)

const LoadingScreen = lazy(
  () => import('./Loading/LoadingScreen').then((module: any) => ({ default: module.LoadingScreen }))
)

const UploadCredentialScreen = lazy(
  () => import('./Onboarding/UploadCredentialScreen/UploadCredentialScreen').then((module: any) => ({ default: module.UploadCredentialScreen }))
)

const UploadStatementsScreen = lazy(
  () => import('./Statements/UploadScreen').then((module: any) => ({ default: module.UploadScreen }))
)

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const themeIntro = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colors.black
    },
    secondary: {
      main: colors.blue500,
    },
    background: {
      default: colors.white,
    },
    error: {
      main: colors.pink500,
    }
  },
})

const themeApp = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colors.pink500
    },
    secondary: {
      main: colors.blue500,
    },
    background: {
      default: colors.white,
    }
  },
})

const AuthorizedRouter = () => {
  const [{ accessToken }] = useSession()
  const [{ demoMode, bulkCredsAccess, enterpriseAccess }] = useFeatureFlags()

  const isAuthenticated = !!accessToken

  useActivityTimeout({
    active: isAuthenticated,
    demoMode
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeApp}>
        {(bulkCredsAccess && enterpriseAccess) ? (
          <Switch>
            <Route path={Routes.uploadCredentials} exact component={UploadCredentialScreen} />
            <Redirect to={Routes.uploadCredentials} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/" exact component={AllClientsScreen} />
            <Route path={Routes.clients} exact component={AllClientsScreen} />
            <Route path={Routes.dashboard} component={ClientDashboardScreen} />
            <Route path={Routes.statements} component={AllStatementsScreen} />
            <Route path={Routes.manage} exact component={ManageServicesScreen} />
            <Route path={Routes.support} component={LoadingScreen} />
            <Route path={Routes.upload} component={UploadStatementsScreen} />
            <Route path={Routes.licenses} exact component={LicenseScreen} />
          </Switch>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function RouterWrapper() {
  const history = useHistory()
  const location = useLocation()
  const analytics = useAnalytics()
  const [{ accessToken }] = useSession()
  const [{ bulkCredsAccess }] = useFeatureFlags()
  const [normalizedDownload] = useNormalizedDownload()

  const [{ initialized }] = useUserDetails()

  const isAuthenticated = !!accessToken

  useEffect(() => {
    // capture initial page load
    analytics.page(location.pathname)

    const unlisten = history.listen((newLocation: any) => {
      analytics.page(newLocation.pathname)
    })

    return () => unlisten()
  }, [])

  useEffect(() => {
    const currentRoute = history.location.pathname

    if (!initialized) return

    const onCheckFlags = async () => {
      if ((isAuthenticated && bulkCredsAccess && currentRoute !== Routes.uploadCredentials)) {
        history.replace(Routes.uploadCredentials)
      }

      // redirect to home screen if unauthenticated
      if ((!isAuthenticated && !bulkCredsAccess && ![Routes.intro, Routes.license].includes(currentRoute))) {
        history.replace(Routes.intro)
      }

      // redirect to home screen if unauthenticated
      if (isAuthenticated && [Routes.intro, Routes.login, Routes.license].includes(currentRoute)) {
        if (bulkCredsAccess) {
          history.replace(Routes.uploadCredentials)
        } else {
          history.replace(Routes.clients)
        }
      }
    }

    onCheckFlags()
  }, [isAuthenticated, initialized])

  useEffect(() => {
    const currentRoute = history.location.pathname
    posthog.capture('$pageview', { router: currentRoute })
  }, [location])

  if (!initialized && isAuthenticated) return null

  const onError = (error: Error) => {
    if (error && error.name === 'ChunkLoadError') {
      console.error(`Something went wrong: ${error.toString()}`)
      window.location.reload()
    }
  }

  return !isAuthenticated ? (
    <ErrorBoundary onError={onError} fallbackRender={() => <></>}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeIntro}>
          <Switch>
            <Route path="/" exact component={LoginScreen} />
            <Route path={Routes.login} component={LoginScreen} />
            <Route path={Routes.pin} exact component={PinScreen} />
            <Route path={Routes.licenses} exact component={LicenseScreen} />
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  ) : (
    <ErrorBoundary onError={onError} fallbackRender={() => <></>}>
      {normalizedDownload.isExporting
        && <TextBox
          isFadeOut={!normalizedDownload.isExporting}
          text={`${normalizedDownload.downloadClientTitle} download in process. ${normalizedDownload.downloadPercentage ? normalizedDownload.downloadPercentage : '0'}%`} />}
      <AuthorizedRouter />
    </ErrorBoundary>

  )
}

export function RootRouter() {
  const [{ accessToken, userId }] = useSession()
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags)

  useEffect(() => {
    const onLoadFeatureFlags = async () => {
      try {
        if (userId) {
          const { data } = await apolloClient.query<CustomClaimsQuery>({
            query: CUSTOM_CLAIMS_QUERY,
          })

          const { hasEnterprise, hasBulkCreds, hasNormalizedDownloads } = data?.customClaims ?? {}

          setFeatureFlags({
            ...featureFlags,
            enterpriseAccess: hasEnterprise,
            bulkCredsAccess: hasBulkCreds,
            normalizedDownloadsAccess: hasNormalizedDownloads
          })

        }
      } catch (error: any) {
        addError(error)
      }
    }

    onLoadFeatureFlags()
  }, [userId])

  const isAuthenticated = !!accessToken

  const { enterpriseAccess, bulkCredsAccess } = featureFlags ?? {}

  const getCurrentHeader = () => {
    const shouldShowIntroHeader = !isAuthenticated
    const shouldShowFullHeader = isAuthenticated && enterpriseAccess && !bulkCredsAccess
    const shouldShowSimpleHeader = isAuthenticated && enterpriseAccess && bulkCredsAccess

    if (shouldShowIntroHeader) return <HeaderIntro />
    if (shouldShowSimpleHeader) return <HeaderSimple />
    if (shouldShowFullHeader) return <Header />
  }

  if (bulkCredsAccess === null && userId && isAuthenticated) return null

  return (
    <Router>
      <ReactNotifications />
      <Suspense fallback={<></>}>
        {getCurrentHeader()}
      </Suspense>
      <Suspense fallback={<></>}>
        <RouterWrapper />
      </Suspense>
    </Router>
  )
}
