export const Assets = {
  hifiLogo: '/assets/hifi_logo.png',
  hifiLogoWhite: '/assets/hifi_logo_white.png',
  hifiLogoBlack: '/assets/hifi_logo_black.svg',
  hifiLogoBlue: '/assets/hifi_logo_blue.png',
  downArrow: '/assets/down-arrow.png',
  downArrowSvg: '/assets/down-arrow.svg',
  undoArrowSvg: '/assets/undo-arrow.svg',
  buttonArrow: '/assets/button-arrow.svg',
  successCheck: '/assets/common/successCheck/Vector@3x.png',
  bank_level_security: '/assets/common/bank_level_security/bank_level_security@3x.png',
  security_entry: '/assets/common/security_entry/security_entry@3x.png',
}
