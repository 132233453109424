
import {
  CREATE_DASHBOARD_MUTATION, DELETE_DASHBOARD_MUTATION, QUERY_DASHBOARD, QUERY_DASHBOARDS, UPDATE_DASHBOARD_MUTATION
} from './queries'

import { addError, formatError } from '../../global/errors'
import { apolloClient } from '../../global/apollo/apollo'
import {
  CreateDashboardMutation, CreateDashboardMutationVariables,
  DashboardQuery, DashboardQueryVariables, DashboardsQuery, Result,
  UpdateDashboardMutation, UpdateDashboardMutationVariables,
  DeleteDashboardMutationVariables,
  DeleteDashboardMutation
} from '../types'

export const fetchDashboard = async ({ id }: DashboardQueryVariables) => {
  const { data, errors } = await apolloClient.query<DashboardQuery, DashboardQueryVariables>({
    query: QUERY_DASHBOARD,
    variables: { id }
  })

  if (errors) {
    const errorMessage = formatError(errors)
    const errorTitled = new Error(`[createDashboard] ${errorMessage}`)

    addError(errorTitled)

    return null
  }

  return data.dashboard
}

export const fetchDashboards = async () => {
  const { data, errors } = await apolloClient.query<DashboardsQuery>({
    query: QUERY_DASHBOARDS,
  })

  if (errors) {
    const errorMessage = formatError(errors)
    const errorTitled = new Error(`[fetchDashboards] ${errorMessage}`)

    addError(errorTitled)

    return []
  }

  if (!data) return []

  return data.dashboards
}

export const createDashboard = async ({ dashboard: dashboardInput }: CreateDashboardMutationVariables) => {
  const { data, errors } = await apolloClient.mutate<CreateDashboardMutation, CreateDashboardMutationVariables>({
    mutation: CREATE_DASHBOARD_MUTATION,
    variables: { dashboard: dashboardInput }
  })

  if (errors) {
    const errorMessage = formatError(errors)
    const errorTitled = new Error(`[createDashboard] ${errorMessage}`)

    addError(errorTitled)
  }

  if (!data) return null

  return data.createDashboard
}

export const updateDashboard = async ({ id, dashboard: dashboardInput }: UpdateDashboardMutationVariables) => {
  const { data, errors } = await apolloClient.mutate<UpdateDashboardMutation, UpdateDashboardMutationVariables>({
    mutation: UPDATE_DASHBOARD_MUTATION,
    variables: { id, dashboard: dashboardInput }
  })

  if (errors) {
    const errorMessage = formatError(errors)
    const errorTitled = new Error(`[updateDashboard] ${errorMessage}`)

    addError(errorTitled)

    return null
  }

  return data?.updateDashboard as Result
}

export const deleteDashboard = async ({ id }: DeleteDashboardMutationVariables) => {
  const { data, errors } = await apolloClient.mutate<DeleteDashboardMutation, DeleteDashboardMutationVariables>({
    mutation: DELETE_DASHBOARD_MUTATION,
    variables: { id }
  })

  if (errors) {
    const errorMessage = formatError(errors)
    const errorTitled = new Error(`[deleteDashboard] ${errorMessage}`)

    addError(errorTitled)

    return null
  }

  return data
}

