/* eslint-disable no-empty-function */

import {
  useState, createContext, useEffect, useCallback
} from 'react'
import { v4 as uuid } from 'uuid'

import {
  deleteDashboardsAll,
  loadDashboards, saveDashboards
} from './storage'
import { DashboardStore, Dashboard, TotalState } from './types'
import {
  fetchDashboards,
  createDashboard,
  updateDashboard as updateDashboardRemote,
  deleteDashboard as deleteDashboardRemote
} from './actions'

import { useSession } from '../auth/hooks'
import { useInitializing } from '../loading/hooks'
import { addError } from '../../global/errors'
import { Money } from '../royalties/totals/types'

// eslint-disable-next-line no-unused-vars
export const emptyDashboard: Dashboard = {
  id: '',
  title: '',
  total: 0,
  userId: '',
  vendors: [],
  vendorAccountIds: [],
  vendorSubaccountIds: [],
  hidden: false
}

export const DashboardTotals = createContext<TotalState>(
  [new Map(), () => { }]
)

export const DashboardContext = createContext<DashboardStore>({
  draft: [emptyDashboard, () => { }],
  current: [undefined, () => undefined],
  dashboards: [[], () => ([])],
  loadingDashboards: [false, () => undefined],
  addDashboard: () => Promise.resolve(''),
  updateDashboard: () => { },
  deleteDashboard: () => { },
})

export const DashboardProvider = ({ children }: { children: any }) => {
  const [{ userId = '' }] = useSession()
  const [initializing] = useInitializing()

  const [totals, setTotals] = useState<Map<string, Money>>(new Map())
  const [draft, setDraft] = useState<Dashboard>(emptyDashboard)
  const [current, setCurrent] = useState<string>(emptyDashboard.id)
  const [dashboards, setDashboards] = useState<Dashboard[]>([])
  const [loadingDashboards, setLoadingDashboards] = useState<boolean>(false)

  useEffect(() => {
    const onLoadDashboards = async () => {
      setLoadingDashboards(true)
      try {
        if (userId) {
          // load local dashboards in case remote is not available
          const loadedDashboards = await loadDashboards()
          if (loadedDashboards.length) {
            setDashboards(loadedDashboards)
          }

          // load remote dashboards to be in sync with saved data
          const remoteDashboards: any[] = await fetchDashboards()
          if (remoteDashboards.length) {
            setDashboards(remoteDashboards)
          }

          setLoadingDashboards(false)
        }
      } catch (error: any) {
        addError(error)
      }
    }

    onLoadDashboards()
  }, [userId])

  const addDashboard = useCallback(async (dashboard: Dashboard) => {
    let dashboardId = uuid()

    if (!dashboards.includes(dashboard)) {
      const createdDashboard = await createDashboard({
        dashboard: {
          title: dashboard.title,
          vendorAccountIds: dashboard.vendorAccountIds,
          vendorSubaccountIds: dashboard.vendorSubaccountIds
        }
      })

      dashboardId = createdDashboard?.id ?? dashboardId

      setDashboards([...dashboards, { ...dashboard, id: dashboardId }])
    }

    return dashboardId
  }, [dashboards])

  const addTotal = (dashboardId: string, total: Money) => {
    const hasNewTotal = !totals.has(dashboardId) || totals.get(dashboardId)?.amount !== total.amount
    if (hasNewTotal) {
      totals.set(dashboardId, total)
      const totalsNew = new Map(totals)
      setTotals(totalsNew)
    }
  }

  const updateDashboard = useCallback(async (dashboard: Dashboard) => {
    const updatable = dashboards.find((d) => d.id === dashboard.id)

    if (!updatable) return

    const existing = dashboards.filter((d) => d.id !== dashboard.id)

    // Asyncly remotely update dashboard for fetch calls
    await updateDashboardRemote({
      id: dashboard.id,
      dashboard: {
        title: dashboard.title,
        vendorAccountIds: dashboard.vendorAccountIds,
        vendorSubaccountIds: dashboard.vendorSubaccountIds
      }
    })

    setDashboards([...existing, { ...dashboard }])
  }, [dashboards])

  const deleteDashboard = async (dashboardId: string) => {
    if (dashboards.find((dashboard) => dashboard.id === dashboardId)) {
      await deleteDashboardRemote({ id: dashboardId })

      setDashboards(dashboards.filter(
        (d) => d.id !== dashboardId
      ))
    }
  }

  useEffect(() => {
    saveDashboards(dashboards)
  }, [dashboards])

  useEffect(() => {
    // Delete all cached dashboards if a user signs out
    if (!userId.length && !initializing) {
      deleteDashboardsAll()
    }
  }, [userId])

  const store: DashboardStore = {
    draft: [draft, setDraft],
    current: [current, setCurrent],
    dashboards: [dashboards, setDashboards],
    loadingDashboards: [loadingDashboards, setLoadingDashboards],
    addDashboard,
    deleteDashboard,
    updateDashboard
  }

  return (
    <DashboardTotals.Provider value={[totals, addTotal]}>
      <DashboardContext.Provider value={store}>
        {children}
      </DashboardContext.Provider>
    </DashboardTotals.Provider>
  )
}
