/* eslint-disable camelcase */
/* eslint-disable no-empty-function */

import { useState, createContext, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'

import {
  DateRange, NormalizedDownload, RoyaltyStore, defaultNormalizedDownloadState
} from './types'
import { METRICS_QUERY } from './totals/queries'
import { Money } from './totals/types'
import { FilterProvider } from './filters/provider'

import { useSession } from '../auth/hooks'
import {
  DateType,
  DownloadingNormalizedPercentageQuery,
  Metrics,
  MetricsQuery,
  StatementsNormalizedUrlQuery,
} from '../types'
import { DOWNLOADING_NORMALIZED_PERCENTAGE_QUERY, STATEMENTS_NORMALIZED_URL_QUERY } from '../normalized/queries'
import { DOWNLOAD_NORMALIZED_DATA_POLL_INTERVAL, DOWNLOAD_NORMALIZED_PERCENTAGE } from '../../ui/constants'
import { downloadFileUri } from '../../global/download'

export const RoyaltyContext = createContext<RoyaltyStore>({
  totals: [new Map(), () => { }],
  dateType: [DateType.SaleAt, () => { }],
  breakdowns: [[], () => { }],
  endDate: [null, () => { }],
  startDate: [null, () => { }],
  currentDateRange: [null, () => { }],
  currentDatePickerStart: [undefined, () => { }],
  metrics: [{} as Metrics, () => { }],
  normalizedDownload: [{} as any, () => { }],
})

export const RoyaltyProvider = ({ children }: { children: any }) => {
  const [{ userId = '' }] = useSession()

  const [totals, setTotals] = useState<Map<string, Money>>(new Map())
  const [dateType, setDateType] = useState<DateType>(DateType.SaleAt)
  const [endDate, setEndDate] = useState<string | null | undefined>(undefined)
  const [startDate, setStartDate] = useState<string | null | undefined>(undefined)
  const [currentDateRange, setCurrentDateRange] = useState<string | null | undefined>(DateRange.all)
  const [currentDatePickerStart, setCurrentDatePickerStart] = useState<string | undefined>(undefined)
  const [normalizedDownload, setNormalizedDownload] = useState<NormalizedDownload>(defaultNormalizedDownloadState)

  const [metricsQuery, metricsResult] = useLazyQuery<
    MetricsQuery
  >(METRICS_QUERY)

  useQuery<StatementsNormalizedUrlQuery>(STATEMENTS_NORMALIZED_URL_QUERY, {
    pollInterval: DOWNLOAD_NORMALIZED_DATA_POLL_INTERVAL,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: {
      filters: normalizedDownload.downloadRoyaltyFilters.lastCalled,
      timeStamp: normalizedDownload.downloadTime
    },
    notifyOnNetworkStatusChange: true,
    skip: normalizedDownload.shouldSkipDownload as boolean,
    onCompleted: ({ statementsNormalizedUrl }) => {
      if (statementsNormalizedUrl) {
        setNormalizedDownload((previousStatus: any) => ({
          ...previousStatus,
          downloadRoyaltyFilters: {
            ...normalizedDownload.downloadRoyaltyFilters,
            lastCalled: {}
          },
          isExporting: false,
          shouldSkipDownload: true,
          shouldSkipDownloadPercentageQuery: true,
        }))
        return downloadFileUri(statementsNormalizedUrl, 'statementsNormalizedData.zip', '_self')
      }
    }
  })

  useQuery<DownloadingNormalizedPercentageQuery>(DOWNLOADING_NORMALIZED_PERCENTAGE_QUERY, {
    pollInterval: DOWNLOAD_NORMALIZED_PERCENTAGE,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: { downloadId: normalizedDownload.downloadId },
    notifyOnNetworkStatusChange: true,
    skip: normalizedDownload.shouldSkipDownloadPercentageQuery,
    onCompleted: ({ downloadingNormalizedPercentage }) => {
      if (parseFloat(downloadingNormalizedPercentage ?? '0') === 100) {
        return setNormalizedDownload((previousStatus: any) => ({
          ...previousStatus,
          shouldSkipDownload: false,
          shouldSkipDownloadPercentageQuery: true,
          downloadPercentage: 99
        }))
      }
      return setNormalizedDownload((previousStatus: any) => ({
        ...previousStatus,
        downloadPercentage: Math.floor(Number(downloadingNormalizedPercentage))
      }))
    }
  })

  useEffect(() => {
    if (userId) {
      metricsQuery()
    }
  }, [userId])

  const addTotal = (dashboardId: string, total: Money) => {
    const hasNewTotal = !totals.has(dashboardId) || totals.get(dashboardId)?.amount !== total.amount
    if (hasNewTotal) {
      totals.set(dashboardId, total)
      const totalsNew = new Map(totals)
      setTotals(totalsNew)
    }
  }

  const metricsFinal = metricsResult?.loading ? {} : metricsResult?.data?.metrics ?? {
    countries: 0,
    projects: 0
  }

  const store: RoyaltyStore = {
    totals: [totals, addTotal],
    dateType: [dateType, setDateType],
    breakdowns: [[], () => { }],
    endDate: [endDate, setEndDate],
    startDate: [startDate, setStartDate],
    currentDateRange: [currentDateRange, setCurrentDateRange],
    currentDatePickerStart: [currentDatePickerStart, setCurrentDatePickerStart],
    metrics: [metricsFinal as Metrics, () => { }],
    normalizedDownload: [normalizedDownload, setNormalizedDownload]
  }

  return (
    <FilterProvider>
      <RoyaltyContext.Provider value={store}>
        {children}
      </RoyaltyContext.Provider>
    </FilterProvider>
  )
}
