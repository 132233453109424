export type FeatureFlags = {
  demoMode: boolean,
  adminMode: boolean,
  isAdmin: boolean,
  enterpriseAccess: boolean
  bulkCredsAccess: boolean
  normalizedDownloadsAccess: boolean
  userHash: string
}

// eslint-disable-next-line import/no-mutable-exports
export const defaultFeatureFlags: FeatureFlags = {
  demoMode: false,
  adminMode: false,
  isAdmin: false,
  enterpriseAccess: false,
  bulkCredsAccess: false,
  normalizedDownloadsAccess: false,
  userHash: ''
}
