import { Envs } from '../global/envs'

export enum Routes {
  intro = '/',
  login = '/login',
  signup = '/signup',
  pin = '/verify',
  uploadCredentials = '/upload-credentials',
  clients = '/clients',
  dashboard = '/clients/:id',
  manage = '/manage',
  manageService = '/manage/vendor/:vendor',
  manageAccount = '/manage/account/:account',
  statements = '/statements',
  support = '/support',
  upload = '/upload',
  license = '/license',
  licenses = '/licenses',
}

export const statementFullTableStyle: any = {
  commonStyle: {
    tableWidth: {
      clientNameWidth: '20%',
      subaccount: '',
      fileName: '',
      service: '12%',
      date: '10%',
      total: '',
      download: '10%',
    }
  },
  adminModeStyle: {
    tableWidth: {
      clientName: '20%',
      subaccount: '',
      fileName: '',
      service: '15%',
      date: '12%',
      total: '12%',
      download: '9%',
    }
  }
}

export const statementTableStyle: any = {
  commonStyle: {
    tableWidth: {
      subaccount: '',
      fileName: '',
      service: '20%',
      date: '15%',
      total: '0',
      download: '15%',
    }
  },
  adminModeStyle: {
    tableWidth: {
      subaccount: '',
      fileName: '',
      service: '15%',
      date: '12%',
      total: '12%',
      download: '10%',
    }
  }
}

const sizes = {
  mobile: '375px',
  tablet: '768px',
  standardDesktop: '1440px',
  hugeMonitor: '2560px',
}

export const devices = {
  mobile: `(min-width: ${sizes.mobile})`,
  tablet: `(min-width: ${sizes.tablet})`,
  standardDesktop: `(min-width: ${sizes.standardDesktop})`,
  hugeMonitor: `(min-width: ${sizes.hugeMonitor})`,
}

export enum BULK_CREDENTIAL_COLUMNS {
  service = 0,
  login = 1,
  password = 2,
  clientName = 3,
  vendorAccountId = 4,
  passwordShowHide = 5,
  status = 6,
}

export enum Status {
  success = 'success',
  failure = 'failure',
  pending = 'pending',
  notStarted = 'not_started',
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ENV_KEY = process.env.REACT_APP_ENV! ?? ''
export const IS_LOCAL = ENV_KEY === Envs.Local
export const IS_DEV = ENV_KEY === Envs.Development
export const IS_PRODUCTION = ENV_KEY === Envs.Production

export const POLL_INTERVAL = 1000

export const DOWNLOAD_NORMALIZED_DATA_POLL_INTERVAL = 6000

export const DOWNLOAD_NORMALIZED_PERCENTAGE = 1000

export const DOWNLOAD_NORMALIZED_MAX_COUNT_LARGE = 45000000
export const DOWNLOAD_NORMALIZED_MAX_COUNT_SMALL = 8000000

export const CONTACT_EMAIL = 'artistfinancialservices@tidal.com'
