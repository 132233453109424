export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  LocalDate: { input: any; output: any; }
  SafeInt: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type Artist = {
  __typename?: 'Artist';
  id?: Maybe<Scalars['String']['output']>;
  listenersWeekly?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum ArtistType {
  Band = 'band',
  SoloArtist = 'solo_artist'
}

export type Authorization = {
  __typename?: 'Authorization';
  attributes: AuthorizationAttributes;
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AuthorizationAttributes = {
  __typename?: 'AuthorizationAttributes';
  amount: Scalars['SafeInt']['output'];
  createdAt: Scalars['LocalDate']['output'];
  summary: Scalars['String']['output'];
};

export enum AuthorizationStatus {
  Authorized = 'Authorized',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Declined = 'Declined'
}

export type AuthorizationsQueryParams = {
  accountId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AuthorizationStatus>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  attributes: BankAccountAttributes;
  id: Scalars['String']['output'];
};

export type BankAccountAttributes = {
  __typename?: 'BankAccountAttributes';
  accountNumber: Scalars['String']['output'];
  available: Scalars['SafeInt']['output'];
  /** @deprecated Use available instead. */
  balance: Scalars['SafeInt']['output'];
  routingNumber: Scalars['String']['output'];
};

export type BankAccountBalance = {
  __typename?: 'BankAccountBalance';
  available?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  iso_currency_code?: Maybe<Scalars['String']['output']>;
  last_updated_datetime: Scalars['String']['output'];
  limit?: Maybe<Scalars['Float']['output']>;
  unofficial_currency_code?: Maybe<Scalars['String']['output']>;
};

export type BankAccountExternal = {
  __typename?: 'BankAccountExternal';
  account_id?: Maybe<Scalars['String']['output']>;
  balances?: Maybe<BankAccountBalance>;
  id: Scalars['String']['output'];
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type BankStatement = {
  __typename?: 'BankStatement';
  attributes: BankStatementAttributes;
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type BankStatementAttributes = {
  __typename?: 'BankStatementAttributes';
  period: Scalars['String']['output'];
};

export type BankTransfer = {
  accountId: Scalars['String']['input'];
  amount: Scalars['Int']['input'];
  idempotencyKey: Scalars['String']['input'];
};

export type BankingLimits = {
  __typename?: 'BankingLimits';
  attributes?: Maybe<LimitsAttributes>;
};

export type Breakdown = {
  __typename?: 'Breakdown';
  rows: Array<BreakdownItem>;
  title: Scalars['String']['output'];
};

export type BreakdownFilter = {
  type: BreakdownTypes;
  value: Scalars['String']['input'];
};

export type BreakdownFilterType = {
  __typename?: 'BreakdownFilterType';
  type: BreakdownTypes;
  value: Scalars['String']['output'];
};

export type BreakdownItem = {
  __typename?: 'BreakdownItem';
  name: Scalars['String']['output'];
  total: Money;
  type?: Maybe<Scalars['String']['output']>;
};

export type BreakdownPeriod = {
  __typename?: 'BreakdownPeriod';
  breakdowns: Array<Breakdown>;
  date?: Maybe<Scalars['LocalDate']['output']>;
  period?: Maybe<Period>;
};

export enum BreakdownTypes {
  Country = 'Country',
  Project = 'Project',
  Royalty = 'Royalty',
  Source = 'Source',
  Vendor = 'Vendor'
}

export type Breakdowns = {
  __typename?: 'Breakdowns';
  full: BreakdownPeriod;
  period: Array<BreakdownPeriod>;
};

export enum CardDesign {
  Black = 'Black',
  Gold = 'Gold',
  Pink = 'Pink'
}

export type CashflowApplication = {
  __typename?: 'CashflowApplication';
  artistType: ArtistType;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  confirmedHifi?: Maybe<Scalars['Boolean']['output']>;
  distributor: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  spotifyArtistId: Scalars['String']['output'];
};

export type CashflowBusinessAccountDetails = {
  address: AddressInput;
  dob: Scalars['LocalDate']['input'];
  shippingAddress?: InputMaybe<AddressInput>;
};

export type CashflowIndividualAccountDetails = {
  shippingAddress?: InputMaybe<AddressInput>;
};

export type CashflowStatus = {
  __typename?: 'CashflowStatus';
  depositsOnHoldByHifi: Scalars['Boolean']['output'];
  depositsPausedByUser: Scalars['Boolean']['output'];
};

export type CashflowToken = {
  __typename?: 'CashflowToken';
  expiresIn: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type CashflowWaitlistApplication = {
  artistType: ArtistType;
  distributor: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  spotifyArtistId: Scalars['String']['input'];
};

export type Counterparty = {
  __typename?: 'Counterparty';
  accountNumber: Scalars['String']['output'];
  accountType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type Credential = {
  __typename?: 'Credential';
  clientName?: Maybe<Scalars['String']['output']>;
  login: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  vendorAccountId: Scalars['String']['output'];
  vendorSlug?: Maybe<Scalars['String']['output']>;
};

export type CredentialInput = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  login: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  vendorAccountId?: InputMaybe<Scalars['String']['input']>;
  vendorSlug?: InputMaybe<Scalars['String']['input']>;
};

export type CredentialRecord = {
  __typename?: 'CredentialRecord';
  id?: Maybe<Scalars['String']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  vendor_account_id?: Maybe<Scalars['String']['output']>;
};

export type CustomClaims = {
  __typename?: 'CustomClaims';
  hasBulkCreds: Scalars['Boolean']['output'];
  hasCashflow: Scalars['Boolean']['output'];
  hasCashflowActivated: Scalars['Boolean']['output'];
  hasCustomDashboards: Scalars['Boolean']['output'];
  hasEnterprise: Scalars['Boolean']['output'];
  hasNormalizedDownloads: Scalars['Boolean']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isApplicant: Scalars['Boolean']['output'];
  userHash: Scalars['String']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  attributes: CustomerAttributes;
  type: CustomerType;
};

export type CustomerAttributes = {
  __typename?: 'CustomerAttributes';
  address: Address;
};

export enum CustomerType {
  BusinessCustomer = 'businessCustomer',
  IndividualCustomer = 'individualCustomer'
}

export type Dashboard = {
  __typename?: 'Dashboard';
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  vendorAccountIds: Array<Scalars['ID']['output']>;
  vendorSubaccountIds: Array<Scalars['ID']['output']>;
};

export type DashboardInput = {
  title: Scalars['String']['input'];
  vendorAccountIds: Array<Scalars['ID']['input']>;
  vendorSubaccountIds: Array<Scalars['ID']['input']>;
};

export enum DateType {
  SaleAt = 'SaleAt',
  StatementAt = 'StatementAt'
}

export type DebitCard = {
  __typename?: 'DebitCard';
  cardholder: Scalars['String']['output'];
  expiry: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last4Digits: Scalars['String']['output'];
};

export type DepositDetails = {
  __typename?: 'DepositDetails';
  fee: Scalars['Float']['output'];
  gross: Scalars['Float']['output'];
  net: Scalars['Float']['output'];
};

export type DepositSummary = {
  __typename?: 'DepositSummary';
  outstandingCollection: Scalars['Float']['output'];
  totalFees: Scalars['Float']['output'];
  totalPaid: Scalars['Float']['output'];
};

export type EarningsDaily = {
  __typename?: 'EarningsDaily';
  available: Scalars['Float']['output'];
  model: Scalars['String']['output'];
  paid: Scalars['Float']['output'];
  realtime: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export enum Feature {
  MembershipAppCompleteModule = 'membership_app_complete_module',
  SoundExchangeModule = 'sound_exchange_module',
  WorkingCapital = 'working_capital'
}

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  hasBulkCreds: Scalars['Boolean']['output'];
};

export type FeatureInterests = {
  __typename?: 'FeatureInterests';
  cashflow: Interest;
  membership: Interest;
  soundexchange: Interest;
};

export enum Filter {
  Country = 'Country',
  Project = 'Project',
  Royalty = 'Royalty',
  Source = 'Source',
  Vendor = 'Vendor'
}

export type HifiDeposit = {
  __typename?: 'HifiDeposit';
  amount: Scalars['Float']['output'];
  depositedAt: Scalars['LocalDate']['output'];
  type: HifiDepositType;
};

export type HifiDepositAggregate = {
  __typename?: 'HifiDepositAggregate';
  amount: Scalars['Float']['output'];
  dailyTransactionId: Scalars['String']['output'];
  disbursementDate: Scalars['Date']['output'];
};

export enum HifiDepositType {
  Daily = 'daily',
  Realtime = 'realtime'
}

export enum HifiTransactionType {
  DepositDistributor = 'depositDistributor',
  DepositHifiDaily = 'depositHifiDaily',
  DepositHifiRealtime = 'depositHifiRealtime',
  WithdrawalHifiFees = 'withdrawalHifiFees'
}

export type InstitutionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum Interest {
  Interested = 'interested',
  NotInterested = 'not_interested',
  Unknown = 'unknown'
}

export type JobOptions = {
  connect?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum JobStatus {
  CaptchaFailure = 'captcha_failure',
  Completed = 'completed',
  CredentialsFailure = 'credentials_failure',
  Disabled = 'disabled',
  Failed = 'failed',
  Initalizing = 'initalizing',
  MfaChoiceRequired = 'mfa_choice_required',
  MfaCodeFailure = 'mfa_code_failure',
  MfaCodeFailureNoRetry = 'mfa_code_failure_no_retry',
  MfaCodeRequired = 'mfa_code_required',
  MfaCodeResend = 'mfa_code_resend',
  MfaQuestionFailure = 'mfa_question_failure',
  MfaQuestionRequired = 'mfa_question_required',
  Running = 'running',
  SessionActive = 'session_active',
  SessionExpired = 'session_expired',
  Skipped = 'skipped',
  Stalled = 'stalled',
  TermsOfServiceFailure = 'terms_of_service_failure',
  Unknown = 'unknown'
}

export enum JobStep {
  Authenticating = 'authenticating',
  Downloading = 'downloading',
  Finished = 'finished',
  Normalizing = 'normalizing',
  Scraping = 'scraping',
  Starting = 'starting',
  Waiting = 'waiting'
}

export type KycForm = {
  __typename?: 'KycForm';
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LimitsAch = {
  __typename?: 'LimitsAch';
  dailyCredit: Scalars['SafeInt']['output'];
  dailyDebit: Scalars['SafeInt']['output'];
  monthlyCredit: Scalars['SafeInt']['output'];
  monthlyDebit: Scalars['SafeInt']['output'];
};

export type LimitsAttributes = {
  __typename?: 'LimitsAttributes';
  ach?: Maybe<LimitsAttributesAch>;
  card?: Maybe<LimitsAttributesCard>;
};

export type LimitsAttributesAch = {
  __typename?: 'LimitsAttributesAch';
  limits?: Maybe<LimitsAch>;
  totalsDaily?: Maybe<TotalsAch>;
  totalsMonthly?: Maybe<TotalsAch>;
};

export type LimitsAttributesCard = {
  __typename?: 'LimitsAttributesCard';
  limits?: Maybe<LimitsCard>;
  totalsDaily?: Maybe<TotalsCard>;
};

export type LimitsCard = {
  __typename?: 'LimitsCard';
  dailyCardTransaction: Scalars['SafeInt']['output'];
  dailyDeposit: Scalars['SafeInt']['output'];
  dailyPurchase: Scalars['SafeInt']['output'];
  dailyWithdrawal: Scalars['SafeInt']['output'];
};

export type ListStatus = {
  __typename?: 'ListStatus';
  waitlisted: Scalars['Boolean']['output'];
  whitelisted: Scalars['Boolean']['output'];
};

export type Metrics = {
  __typename?: 'Metrics';
  countries: Scalars['Int']['output'];
  projects: Scalars['Int']['output'];
};

export type MfaAnswerInput = {
  answer: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  vendorAccountId: Scalars['ID']['input'];
};

export type MfaChoice = {
  __typename?: 'MfaChoice';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type MfaQuestion = {
  __typename?: 'MfaQuestion';
  id: Scalars['String']['output'];
  status: MfaQuestionStatus;
  text: Scalars['String']['output'];
};

export enum MfaQuestionStatus {
  Answered = 'answered',
  Incorrect = 'incorrect',
  Unanswered = 'unanswered'
}

export type Money = {
  __typename?: 'Money';
  amount: Scalars['BigDecimal']['output'];
  currency: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  addVendors: Result;
  cashflowBusinessAccount: BankAccount;
  cashflowIndividualAccount: BankAccount;
  cashflowWaitlist: Result;
  confirmApplication: Result;
  createDashboard?: Maybe<Dashboard>;
  createKycForm: KycForm;
  createVendorAccount: VendorAccount;
  deleteAccount: Result;
  deleteAccountSoft: Result;
  deleteDashboard: Result;
  deleteVendorAccount: Result;
  generatePlaidLinkToken?: Maybe<PlaidLinkToken>;
  inputMfaAnswer: Result;
  inputMfaChoice: Result;
  inputMfaCode: Result;
  login: Verification;
  loginSignup: Verification;
  logout: Result;
  removeBankAccountExternal?: Maybe<Result>;
  resendCode: Verification;
  resendMfaCode: Result;
  saveCashflowActivated: Result;
  saveFeatureInterest: Result;
  saveNotificationPreference: Result;
  savePlaidAccount?: Maybe<Result>;
  saveStatementsNormalized: StatementsNormalizedResult;
  saveSuggestion: Result;
  signup: Verification;
  startVendorJob: Result;
  toggleMfaCredential: Result;
  toggleSubaccount: Result;
  toggleSubaccounts: Result;
  transferExternal?: Maybe<Result>;
  updateCredential: Result;
  updateCredentials: Result;
  updateDashboard: Result;
  updateMfaCredential: Result;
  verify: Session;
};


export type MutationAddVendorsArgs = {
  vendorSlugs: Array<VendorSlug>;
};


export type MutationCashflowBusinessAccountArgs = {
  details?: InputMaybe<CashflowBusinessAccountDetails>;
};


export type MutationCashflowIndividualAccountArgs = {
  details?: InputMaybe<CashflowIndividualAccountDetails>;
};


export type MutationCashflowWaitlistArgs = {
  application?: InputMaybe<CashflowWaitlistApplication>;
};


export type MutationConfirmApplicationArgs = {
  confirm: Scalars['Boolean']['input'];
};


export type MutationCreateDashboardArgs = {
  dashboard: DashboardInput;
};


export type MutationCreateVendorAccountArgs = {
  credential?: InputMaybe<CredentialInput>;
  vendorSlug: VendorSlug;
};


export type MutationDeleteDashboardArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteVendorAccountArgs = {
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationInputMfaAnswerArgs = {
  input: MfaAnswerInput;
};


export type MutationInputMfaChoiceArgs = {
  choiceId: Scalars['String']['input'];
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationInputMfaCodeArgs = {
  code: Scalars['String']['input'];
  manual?: InputMaybe<Scalars['Boolean']['input']>;
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationLoginSignupArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationRemoveBankAccountExternalArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResendCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationResendMfaCodeArgs = {
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationSaveCashflowActivatedArgs = {
  timeZone: Scalars['String']['input'];
};


export type MutationSaveFeatureInterestArgs = {
  feature: Feature;
  interest: Interest;
};


export type MutationSaveNotificationPreferenceArgs = {
  enabled: Scalars['Boolean']['input'];
};


export type MutationSavePlaidAccountArgs = {
  institution: InstitutionInput;
  linkSessionId?: InputMaybe<Scalars['String']['input']>;
  publicToken: Scalars['String']['input'];
};


export type MutationSaveStatementsNormalizedArgs = {
  filterTags?: InputMaybe<Scalars['String']['input']>;
  filters: RoyaltyFilters;
};


export type MutationSaveSuggestionArgs = {
  suggestion: Scalars['String']['input'];
};


export type MutationSignupArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationStartVendorJobArgs = {
  options?: InputMaybe<JobOptions>;
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationToggleMfaCredentialArgs = {
  bulkReady?: InputMaybe<Scalars['Boolean']['input']>;
  mfaBulkReady?: InputMaybe<Scalars['Boolean']['input']>;
  mfaManualBulkReady?: InputMaybe<Scalars['Boolean']['input']>;
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationToggleSubaccountArgs = {
  enabled: Scalars['Boolean']['input'];
  subaccountId: Scalars['ID']['input'];
};


export type MutationToggleSubaccountsArgs = {
  enabled: Scalars['Boolean']['input'];
  subaccountIds: Array<Scalars['ID']['input']>;
};


export type MutationTransferExternalArgs = {
  transfer?: InputMaybe<BankTransfer>;
};


export type MutationUpdateCredentialArgs = {
  credential: CredentialInput;
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationUpdateCredentialsArgs = {
  credentials: Array<InputMaybe<CredentialInput>>;
};


export type MutationUpdateDashboardArgs = {
  dashboard: DashboardInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateMfaCredentialArgs = {
  bulkReady?: InputMaybe<Scalars['Boolean']['input']>;
  credential: CredentialInput;
  vendorAccountId: Scalars['ID']['input'];
};


export type MutationVerifyArgs = {
  pin: Scalars['String']['input'];
  verificationId: Scalars['ID']['input'];
};

export type NormalizedDownloadHistoryRecord = {
  __typename?: 'NormalizedDownloadHistoryRecord';
  activityStatus?: Maybe<NormalizedDownloadHistoryStatus>;
  downloadId?: Maybe<Scalars['String']['output']>;
  filterTags?: Maybe<Scalars['String']['output']>;
  gcsFileName?: Maybe<Scalars['String']['output']>;
  gcsFilePath?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  queryParameters?: Maybe<QueryParameters>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  useCurrency?: Maybe<Scalars['Boolean']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum NormalizedDownloadHistoryStatus {
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Running = 'running',
  Skipped = 'skipped',
  Succeeded = 'succeeded'
}

export enum Period {
  Daily = 'Daily',
  Full = 'Full',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly',
  Yearly = 'Yearly'
}

export type PeriodTotal = {
  __typename?: 'PeriodTotal';
  date: Scalars['String']['output'];
  total: Money;
};

export type PlaidLinkToken = {
  __typename?: 'PlaidLinkToken';
  expiration?: Maybe<Scalars['String']['output']>;
  link_token?: Maybe<Scalars['String']['output']>;
  request_id?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']['output']>;
  application?: Maybe<CashflowApplication>;
  artist?: Maybe<Artist>;
  bankAccountExternalBalance?: Maybe<BankAccountExternal>;
  bankAccountsExternal?: Maybe<Array<Maybe<BankAccountExternal>>>;
  bankingLimits?: Maybe<BankingLimits>;
  breakdownPeriods?: Maybe<Breakdowns>;
  breakdowns?: Maybe<Breakdowns>;
  cache?: Maybe<Result>;
  cashflowAccount?: Maybe<BankAccount>;
  cashflowAuthorizations: Array<Authorization>;
  cashflowBankLetter: Scalars['String']['output'];
  cashflowCard: DebitCard;
  cashflowCustomer: Customer;
  cashflowDeposits: Array<HifiDepositAggregate>;
  cashflowDepositsRealtime: Array<HifiDeposit>;
  cashflowFee: Scalars['Float']['output'];
  cashflowKycForm: KycForm;
  cashflowMobileWalletPayload: Scalars['String']['output'];
  cashflowStatement: Scalars['String']['output'];
  cashflowStatements: Array<BankStatement>;
  cashflowStatus: CashflowStatus;
  cashflowToken: CashflowToken;
  cashflowTransaction: Transaction;
  cashflowTransactions: Array<Transaction>;
  countries?: Maybe<Breakdowns>;
  currencies: Array<Scalars['String']['output']>;
  customClaims: CustomClaims;
  dashboard?: Maybe<Dashboard>;
  dashboards: Array<Maybe<Dashboard>>;
  depositDetails: DepositDetails;
  depositSummary: DepositSummary;
  downloadingNormalizedPercentage?: Maybe<Scalars['String']['output']>;
  earningsHistoric?: Maybe<EarningsDaily>;
  earningsPrediction: EarningsDaily;
  earningsRealtime: EarningsDaily;
  lastCredentialsSubmitted?: Maybe<Array<CredentialRecord>>;
  metrics: Metrics;
  normalizedDownloadsHistory?: Maybe<Array<Maybe<NormalizedDownloadHistoryRecord>>>;
  projects?: Maybe<Breakdowns>;
  refresh?: Maybe<Scalars['Boolean']['output']>;
  sources?: Maybe<Breakdowns>;
  spotifyArtist?: Maybe<SpotifyArtist>;
  spotifySearchArtists?: Maybe<Array<Maybe<SpotifyArtist>>>;
  statementUrl: Scalars['String']['output'];
  statements: Array<Statement>;
  statementsNormalizedCount?: Maybe<Scalars['String']['output']>;
  statementsNormalizedUrl?: Maybe<Scalars['String']['output']>;
  suggestions: Array<Scalars['String']['output']>;
  totals?: Maybe<Totals>;
  trackMetrics?: Maybe<TrackMetrics>;
  tracks?: Maybe<Array<Maybe<Track>>>;
  uploadFileExists: Scalars['Boolean']['output'];
  user?: Maybe<User>;
  vendorAccountBalance?: Maybe<VendorAccountBalance>;
  vendorAccounts: Array<VendorAccount>;
  vendorJob?: Maybe<VendorJob>;
  vendorJobs: Array<VendorJob>;
  vendors: Array<Vendor>;
};


export type QueryArtistArgs = {
  vendorAccountId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBankAccountExternalBalanceArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBreakdownPeriodsArgs = {
  filters: RoyaltyFilters;
};


export type QueryBreakdownsArgs = {
  filters: RoyaltyFilters;
};


export type QueryCacheArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCashflowAuthorizationsArgs = {
  params: AuthorizationsQueryParams;
};


export type QueryCashflowDepositsArgs = {
  days: Scalars['Int']['input'];
};


export type QueryCashflowDepositsRealtimeArgs = {
  disbursementId: Scalars['String']['input'];
};


export type QueryCashflowFeeArgs = {
  date: Scalars['Date']['input'];
};


export type QueryCashflowMobileWalletPayloadArgs = {
  signedNonce: Scalars['String']['input'];
};


export type QueryCashflowStatementArgs = {
  id: Scalars['String']['input'];
  isPdf?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCashflowTransactionArgs = {
  id: Scalars['String']['input'];
};


export type QueryCashflowTransactionsArgs = {
  params: TransactionsQueryParams;
};


export type QueryCountriesArgs = {
  filters: RoyaltyFilters;
};


export type QueryCurrenciesArgs = {
  filters: RoyaltyFilters;
};


export type QueryDashboardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDashboardsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryDepositDetailsArgs = {
  disbursementId: Scalars['String']['input'];
};


export type QueryDownloadingNormalizedPercentageArgs = {
  downloadId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEarningsHistoricArgs = {
  date: Scalars['Date']['input'];
};


export type QueryEarningsPredictionArgs = {
  timeZone?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNormalizedDownloadsHistoryArgs = {
  dashboardId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProjectsArgs = {
  filters: RoyaltyFilters;
};


export type QuerySourcesArgs = {
  filters: RoyaltyFilters;
};


export type QuerySpotifyArtistArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpotifySearchArtistsArgs = {
  request?: InputMaybe<SpotifySearchRequest>;
};


export type QueryStatementUrlArgs = {
  jobId: Scalars['String']['input'];
  statementId: Scalars['String']['input'];
};


export type QueryStatementsArgs = {
  filters: RoyaltyFilters;
};


export type QueryStatementsNormalizedCountArgs = {
  filters: RoyaltyFilters;
};


export type QueryStatementsNormalizedUrlArgs = {
  filters: RoyaltyFilters;
  timeStamp?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTotalsArgs = {
  filters: RoyaltyFilters;
};


export type QueryTrackMetricsArgs = {
  distributorId?: InputMaybe<Scalars['String']['input']>;
  dspId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTracksArgs = {
  vendorAccountId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUploadFileExistsArgs = {
  fileName: Scalars['String']['input'];
};


export type QueryVendorAccountBalanceArgs = {
  jobId: Scalars['ID']['input'];
};


export type QueryVendorJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryParameters = {
  __typename?: 'QueryParameters';
  filters?: Maybe<RoyaltyFiltersType>;
  jobIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Result = {
  __typename?: 'Result';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type RoyaltyFilters = {
  currency?: InputMaybe<Scalars['String']['input']>;
  dashboardIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateType?: InputMaybe<DateType>;
  endDate?: InputMaybe<Scalars['LocalDate']['input']>;
  period?: InputMaybe<Period>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
  values?: InputMaybe<Array<BreakdownFilter>>;
  vendorAccountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  vendorSlugs?: InputMaybe<Array<VendorSlug>>;
  vendorSubaccountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RoyaltyFiltersType = {
  __typename?: 'RoyaltyFiltersType';
  currency?: Maybe<Scalars['String']['output']>;
  dashboardIds?: Maybe<Array<Scalars['ID']['output']>>;
  dateType?: Maybe<DateType>;
  endDate?: Maybe<Scalars['LocalDate']['output']>;
  period?: Maybe<Period>;
  startDate?: Maybe<Scalars['LocalDate']['output']>;
  values?: Maybe<Array<BreakdownFilterType>>;
  vendorAccountIds?: Maybe<Array<Scalars['ID']['output']>>;
  vendorSlugs?: Maybe<Array<VendorSlug>>;
  vendorSubaccountIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type Session = {
  __typename?: 'Session';
  accountId?: Maybe<Scalars['ID']['output']>;
  jwt?: Maybe<Scalars['String']['output']>;
  session?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  verification: Verification;
};

export type SpotifyArtist = {
  __typename?: 'SpotifyArtist';
  followers?: Maybe<Scalars['Int']['output']>;
  genres?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<SpotifyImage>>>;
  name?: Maybe<Scalars['String']['output']>;
  popularity?: Maybe<Scalars['Int']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type SpotifyImage = {
  __typename?: 'SpotifyImage';
  height?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type SpotifySearchRequest = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type Statement = {
  __typename?: 'Statement';
  createdAt: Scalars['LocalDate']['output'];
  date?: Maybe<Scalars['LocalDate']['output']>;
  id: Scalars['ID']['output'];
  jobId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subaccountId?: Maybe<Scalars['ID']['output']>;
  total?: Maybe<Scalars['BigDecimal']['output']>;
  vendorAccountId: Scalars['ID']['output'];
  vendorSlug: VendorSlug;
};

export type StatementsNormalizedResult = {
  __typename?: 'StatementsNormalizedResult';
  downloadId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StatementsNormalizedStatusTypes>;
};

export enum StatementsNormalizedStatusTypes {
  Failure = 'failure',
  Pending = 'pending',
  Success = 'success'
}

export enum SubaccountCategory {
  Artist = 'artist',
  Neighbouring = 'neighbouring',
  Publishing = 'publishing',
  Recording = 'recording',
  RightsOwner = 'rights_owner'
}

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type Totals = {
  __typename?: 'Totals';
  full: PeriodTotal;
  period: Array<PeriodTotal>;
};

export type TotalsAch = {
  __typename?: 'TotalsAch';
  credits: Scalars['SafeInt']['output'];
  debits: Scalars['SafeInt']['output'];
};

export type TotalsCard = {
  __typename?: 'TotalsCard';
  cardTransactions: Scalars['SafeInt']['output'];
  deposits: Scalars['SafeInt']['output'];
  purchases: Scalars['SafeInt']['output'];
  withdrawals: Scalars['SafeInt']['output'];
};

export type Track = {
  __typename?: 'Track';
  cashflowEnabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isrc?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  vendorAccountId?: Maybe<Scalars['String']['output']>;
};

export type TrackMetrics = {
  __typename?: 'TrackMetrics';
  trackCount?: Maybe<Scalars['Int']['output']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  attributes: TransactionAttributes;
  id: Scalars['String']['output'];
  relationships: TransactionRelationships;
  type: Scalars['String']['output'];
};

export type TransactionAttributes = {
  __typename?: 'TransactionAttributes';
  amount: Scalars['SafeInt']['output'];
  counterparty?: Maybe<Counterparty>;
  createdAt: Scalars['LocalDate']['output'];
  direction: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  tags?: Maybe<TransactionTags>;
};

export type TransactionRelationships = {
  __typename?: 'TransactionRelationships';
  counterpartyAccount?: Maybe<UnitRelationship>;
};

export type TransactionTags = {
  __typename?: 'TransactionTags';
  hifiCollectionAmount?: Maybe<Scalars['String']['output']>;
  hifiDisbursementId?: Maybe<Scalars['String']['output']>;
  hifiDisbursementTotal?: Maybe<Scalars['String']['output']>;
  hifiDistributorSlug?: Maybe<VendorSlug>;
  hifiTransactionType?: Maybe<HifiTransactionType>;
};

export type TransactionsQueryParams = {
  accountId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type UnitRelationship = {
  __typename?: 'UnitRelationship';
  data?: Maybe<UnitRelationshipData>;
};

export type UnitRelationshipData = {
  __typename?: 'UnitRelationshipData';
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  accountId: Scalars['ID']['output'];
  allowlisted: Scalars['Boolean']['output'];
  fileUploadsEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interests: FeatureInterests;
  memberApplicationSubmitted: Scalars['Boolean']['output'];
  notificationsEnabled: Scalars['Boolean']['output'];
  onboarded: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  createdAt?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  mfa?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<VendorStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type VendorAccount = {
  __typename?: 'VendorAccount';
  bulkReady: Scalars['Boolean']['output'];
  cashflowEnabled: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latestJob?: Maybe<Scalars['ID']['output']>;
  latestSuccessfulJob?: Maybe<Scalars['ID']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  mfaBulkReady: Scalars['Boolean']['output'];
  mfaManualBulkReady?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<VendorAccountStatus>;
  subaccounts: Array<VendorSubaccount>;
  updatedAt: Scalars['String']['output'];
  vendorSlug: VendorSlug;
};

export type VendorAccountBalance = {
  __typename?: 'VendorAccountBalance';
  balanceAmount?: Maybe<Scalars['Float']['output']>;
  balanceCurrency?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  jobId: Scalars['ID']['output'];
  payoutDestination?: Maybe<Scalars['String']['output']>;
  payoutDestinationTrusted?: Maybe<Scalars['Boolean']['output']>;
  payoutThreshold?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['String']['output'];
};

export enum VendorAccountStatus {
  Connected = 'connected',
  Degraded = 'degraded',
  Disconnected = 'disconnected',
  Hidden = 'hidden',
  Linking = 'linking',
  NeedsAttention = 'needs_attention'
}

export type VendorJob = {
  __typename?: 'VendorJob';
  credentialsIncorrect?: Maybe<Scalars['Boolean']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mfaChoices: Array<MfaChoice>;
  mfaQuestions: Array<MfaQuestion>;
  status?: Maybe<JobStatus>;
  step?: Maybe<JobStep>;
  targets?: Maybe<Scalars['String']['output']>;
  vendorAccountId: Scalars['ID']['output'];
  vendorSlug?: Maybe<VendorSlug>;
};

export enum VendorSlug {
  Actra = 'actra',
  Ascap = 'ascap',
  Awal = 'awal',
  BandCamp = 'band_camp',
  Bmg = 'bmg',
  Bmi = 'bmi',
  CdBaby = 'cd_baby',
  Cmrra = 'cmrra',
  Concord = 'concord',
  Distrokid = 'distrokid',
  Ditto = 'ditto',
  Empire = 'empire',
  Gmr = 'gmr',
  Hifi = 'hifi',
  Kobalt = 'kobalt',
  Level = 'level',
  Mlc = 'mlc',
  Mroc = 'mroc',
  MushroomAvex = 'mushroom_avex',
  MushroomMusic = 'mushroom_music',
  Orchard = 'orchard',
  Platoon = 'platoon',
  PlatoonAwal = 'platoon_awal',
  Ppl = 'ppl',
  Prs = 'prs',
  Repost = 'repost',
  ReverbNation = 'reverb_nation',
  Sesac = 'sesac',
  Socan = 'socan',
  Songtrust = 'songtrust',
  SonyMusic = 'sony_music',
  SonyMusicPublishing = 'sony_music_publishing',
  SoundExchange = 'sound_exchange',
  Spotify = 'spotify',
  Stem = 'stem',
  Symphonic = 'symphonic',
  Tunecore = 'tunecore',
  Umg = 'umg',
  Umpg = 'umpg',
  UnitedMasters = 'united_masters',
  WarnerChappell = 'warner_chappell',
  WarnerMusic = 'warner_music'
}

export enum VendorStatus {
  Degraded = 'degraded',
  Hidden = 'hidden',
  Offline = 'offline',
  Online = 'online',
  Unimplemented = 'unimplemented'
}

export type VendorSubaccount = {
  __typename?: 'VendorSubaccount';
  category?: Maybe<SubaccountCategory>;
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  vendorAccountId: Scalars['ID']['output'];
};

export type Verification = {
  __typename?: 'Verification';
  exists?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<VerificationStatus>;
};

export enum VerificationStatus {
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export type CustomClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomClaimsQuery = { __typename?: 'Query', customClaims: { __typename?: 'CustomClaims', isAdmin: boolean, hasCustomDashboards: boolean, hasEnterprise: boolean, hasBulkCreds: boolean, hasNormalizedDownloads: boolean, userHash: string } };

export type LoginMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Verification', id: string, status?: VerificationStatus } };

export type SignupMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'Verification', id: string, status?: VerificationStatus } };

export type VerifyMutationVariables = Exact<{
  verificationId: Scalars['ID']['input'];
  pin: Scalars['String']['input'];
}>;


export type VerifyMutation = { __typename?: 'Mutation', verify: { __typename?: 'Session', userId?: string, accountId?: string, jwt?: string, verification: { __typename?: 'Verification', id: string, status?: VerificationStatus } } };

export type ResendCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;


export type ResendCodeMutation = { __typename?: 'Mutation', resendCode: { __typename?: 'Verification', id: string, status?: VerificationStatus } };

export type LoadingScreenQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadingScreenQuery = { __typename?: 'Query', suggestions: Array<string>, user?: { __typename?: 'User', onboarded: boolean, phoneNumber: string, allowlisted: boolean, fileUploadsEnabled: boolean, notificationsEnabled: boolean, memberApplicationSubmitted: boolean, interests: { __typename?: 'FeatureInterests', cashflow: Interest, membership: Interest, soundexchange: Interest } } };

export type RefreshQueryVariables = Exact<{ [key: string]: never; }>;


export type RefreshQuery = { __typename?: 'Query', refresh?: boolean };

export type DashboardQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DashboardQuery = { __typename?: 'Query', dashboard?: { __typename?: 'Dashboard', id: string, title: string, userId: string, vendorAccountIds: Array<string>, vendorSubaccountIds: Array<string> } };

export type DashboardsQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardsQuery = { __typename?: 'Query', dashboards: Array<{ __typename?: 'Dashboard', id: string, title: string, userId: string, vendorAccountIds: Array<string>, vendorSubaccountIds: Array<string>, hidden: boolean }> };

export type DeleteDashboardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDashboardMutation = { __typename?: 'Mutation', deleteDashboard: { __typename?: 'Result', status?: string, message?: string } };

export type CreateDashboardMutationVariables = Exact<{
  dashboard: DashboardInput;
}>;


export type CreateDashboardMutation = { __typename?: 'Mutation', createDashboard?: { __typename?: 'Dashboard', id: string, title: string, userId: string, vendorAccountIds: Array<string>, vendorSubaccountIds: Array<string> } };

export type UpdateDashboardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  dashboard: DashboardInput;
}>;


export type UpdateDashboardMutation = { __typename?: 'Mutation', updateDashboard: { __typename?: 'Result', status?: string, message?: string } };

export type SaveStatementsNormalizedMutationVariables = Exact<{
  filters: RoyaltyFilters;
  filterTags?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveStatementsNormalizedMutation = { __typename?: 'Mutation', saveStatementsNormalized: { __typename?: 'StatementsNormalizedResult', status?: StatementsNormalizedStatusTypes, message?: string, downloadId?: string } };

export type StatementsNormalizedUrlQueryVariables = Exact<{
  filters: RoyaltyFilters;
  timeStamp?: InputMaybe<Scalars['String']['input']>;
}>;


export type StatementsNormalizedUrlQuery = { __typename?: 'Query', statementsNormalizedUrl?: string };

export type DownloadingNormalizedPercentageQueryVariables = Exact<{
  downloadId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DownloadingNormalizedPercentageQuery = { __typename?: 'Query', downloadingNormalizedPercentage?: string };

export type NormalizedDownloadsHistoryQueryVariables = Exact<{
  dashboardId?: InputMaybe<Scalars['String']['input']>;
}>;


export type NormalizedDownloadsHistoryQuery = { __typename?: 'Query', normalizedDownloadsHistory?: Array<{ __typename?: 'NormalizedDownloadHistoryRecord', id?: string, userId?: string, downloadId?: string, gcsFileName?: string, filterTags?: string, useCurrency?: boolean, updatedAt?: string, activityStatus?: NormalizedDownloadHistoryStatus, queryParameters?: { __typename?: 'QueryParameters', jobIds?: Array<string>, filters?: { __typename?: 'RoyaltyFiltersType', currency?: string, dashboardIds?: Array<string>, dateType?: DateType, endDate?: any, period?: Period, startDate?: any, vendorAccountIds?: Array<string>, vendorSlugs?: Array<VendorSlug>, vendorSubaccountIds?: Array<string>, values?: Array<{ __typename?: 'BreakdownFilterType', value: string, type: BreakdownTypes }> } } }> };

export type StatementsNormalizedCountQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type StatementsNormalizedCountQuery = { __typename?: 'Query', statementsNormalizedCount?: string };

export type BreakdownsQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type BreakdownsQuery = { __typename?: 'Query', breakdowns?: { __typename?: 'Breakdowns', full: { __typename?: 'BreakdownPeriod', breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, type?: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> } } };

export type BreakdownsPeriodQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type BreakdownsPeriodQuery = { __typename?: 'Query', breakdownPeriods?: { __typename?: 'Breakdowns', period: Array<{ __typename?: 'BreakdownPeriod', date?: any, breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, type?: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> }> } };

export type ProjectsQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type ProjectsQuery = { __typename?: 'Query', projects?: { __typename?: 'Breakdowns', full: { __typename?: 'BreakdownPeriod', breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, type?: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> }, period: Array<{ __typename?: 'BreakdownPeriod', date?: any, breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, type?: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> }> } };

export type SourcesQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type SourcesQuery = { __typename?: 'Query', sources?: { __typename?: 'Breakdowns', full: { __typename?: 'BreakdownPeriod', breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> }, period: Array<{ __typename?: 'BreakdownPeriod', date?: any, breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> }> } };

export type CountriesQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type CountriesQuery = { __typename?: 'Query', countries?: { __typename?: 'Breakdowns', full: { __typename?: 'BreakdownPeriod', breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> }, period: Array<{ __typename?: 'BreakdownPeriod', date?: any, breakdowns: Array<{ __typename?: 'Breakdown', title: string, rows: Array<{ __typename?: 'BreakdownItem', name: string, total: { __typename?: 'Money', amount: any, currency: string } }> }> }> } };

export type MetricsQueryVariables = Exact<{ [key: string]: never; }>;


export type MetricsQuery = { __typename?: 'Query', metrics: { __typename?: 'Metrics', countries: number, projects: number } };

export type CurrenciesQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type CurrenciesQuery = { __typename?: 'Query', currencies: Array<string> };

export type TotalsQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type TotalsQuery = { __typename?: 'Query', totals?: { __typename?: 'Totals', full: { __typename?: 'PeriodTotal', total: { __typename?: 'Money', amount: any, currency: string } }, period: Array<{ __typename?: 'PeriodTotal', date: string, total: { __typename?: 'Money', amount: any, currency: string } }> } };

export type StatementsQueryVariables = Exact<{
  filters: RoyaltyFilters;
}>;


export type StatementsQuery = { __typename?: 'Query', statements: Array<{ __typename?: 'Statement', id: string, name: string, date?: any, vendorSlug: VendorSlug, total?: any, vendorAccountId: string, subaccountId?: string, createdAt: any, jobId: string }> };

export type StatementUrlQueryVariables = Exact<{
  jobId: Scalars['String']['input'];
  statementId: Scalars['String']['input'];
}>;


export type StatementUrlQuery = { __typename?: 'Query', statementUrl: string };

export type UploadFileExistsQueryVariables = Exact<{
  fileName: Scalars['String']['input'];
}>;


export type UploadFileExistsQuery = { __typename?: 'Query', uploadFileExists: boolean };

export type VendorsQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorsQuery = { __typename?: 'Query', vendors: Array<{ __typename?: 'Vendor', id?: string, name?: string, slug?: string, status?: VendorStatus, enabled?: boolean, mfa?: Array<string>, createdAt?: string, updatedAt?: string }> };

export type VendorAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorAccountsQuery = { __typename?: 'Query', vendorAccounts: Array<{ __typename?: 'VendorAccount', id: string, vendorSlug: VendorSlug, login?: string, status?: VendorAccountStatus, enabled: boolean, bulkReady: boolean, mfaBulkReady: boolean, cashflowEnabled: boolean, mfaManualBulkReady?: boolean, latestSuccessfulJob?: string, updatedAt: string, subaccounts: Array<{ __typename?: 'VendorSubaccount', id: string, vendorAccountId: string, displayName: string, category?: SubaccountCategory, enabled: boolean }> }> };

export type VendorJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorJobsQuery = { __typename?: 'Query', vendorJobs: Array<{ __typename?: 'VendorJob', id: string, credentialsIncorrect?: boolean, vendorAccountId: string, step?: JobStep, status?: JobStatus, error?: string, mfaChoices: Array<{ __typename?: 'MfaChoice', id: string, text: string }>, mfaQuestions: Array<{ __typename?: 'MfaQuestion', id: string, text: string, status: MfaQuestionStatus }> }> };

export type ManageServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ManageServicesQuery = { __typename?: 'Query', suggestions: Array<string>, user?: { __typename?: 'User', allowlisted: boolean }, vendorAccounts: Array<{ __typename?: 'VendorAccount', id: string, vendorSlug: VendorSlug, login?: string, status?: VendorAccountStatus, enabled: boolean, latestSuccessfulJob?: string, subaccounts: Array<{ __typename?: 'VendorSubaccount', id: string, vendorAccountId: string, displayName: string, category?: SubaccountCategory, enabled: boolean }> }> };

export type LastCredentialsSubmittedQueryVariables = Exact<{ [key: string]: never; }>;


export type LastCredentialsSubmittedQuery = { __typename?: 'Query', lastCredentialsSubmitted?: Array<{ __typename?: 'CredentialRecord', login?: string, updatedAt?: string }> };

export type CreateVendorAccountMutationVariables = Exact<{
  vendorSlug: VendorSlug;
  credential: CredentialInput;
}>;


export type CreateVendorAccountMutation = { __typename?: 'Mutation', createVendorAccount: { __typename?: 'VendorAccount', id: string, vendorSlug: VendorSlug, login?: string, status?: VendorAccountStatus, enabled: boolean, bulkReady: boolean, latestSuccessfulJob?: string, subaccounts: Array<{ __typename?: 'VendorSubaccount', id: string, vendorAccountId: string, displayName: string, category?: SubaccountCategory, enabled: boolean }> } };

export type StartVendorJobMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
}>;


export type StartVendorJobMutation = { __typename?: 'Mutation', startVendorJob: { __typename?: 'Result', status?: string, message?: string } };

export type UpdateMfaCredentialMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
  credential: CredentialInput;
  bulkReady?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateMfaCredentialMutation = { __typename?: 'Mutation', updateMfaCredential: { __typename?: 'Result', status?: string, message?: string } };

export type ToggleCredentialMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
  bulkReady?: InputMaybe<Scalars['Boolean']['input']>;
  mfaBulkReady?: InputMaybe<Scalars['Boolean']['input']>;
  mfaManualBulkReady?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ToggleCredentialMutation = { __typename?: 'Mutation', toggleMfaCredential: { __typename?: 'Result', status?: string, message?: string } };

export type UpdateCredentialMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
  credential: CredentialInput;
}>;


export type UpdateCredentialMutation = { __typename?: 'Mutation', updateCredential: { __typename?: 'Result', status?: string, message?: string } };

export type UpdateCredentialsMutationVariables = Exact<{
  credentials: Array<InputMaybe<CredentialInput>> | InputMaybe<CredentialInput>;
}>;


export type UpdateCredentialsMutation = { __typename?: 'Mutation', updateCredentials: { __typename?: 'Result', status?: string, message?: string } };

export type ToggleSubaccountMutationVariables = Exact<{
  subaccountId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type ToggleSubaccountMutation = { __typename?: 'Mutation', toggleSubaccount: { __typename?: 'Result', status?: string, message?: string } };

export type ToggleSubaccountsMutationVariables = Exact<{
  subaccountIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type ToggleSubaccountsMutation = { __typename?: 'Mutation', toggleSubaccounts: { __typename?: 'Result', status?: string, message?: string } };

export type SuggestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SuggestionsQuery = { __typename?: 'Query', suggestions: Array<string> };

export type SubmitMfaAnswerMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
  questionId: Scalars['String']['input'];
  answer: Scalars['String']['input'];
}>;


export type SubmitMfaAnswerMutation = { __typename?: 'Mutation', inputMfaAnswer: { __typename?: 'Result', status?: string, message?: string } };

export type SubmitMfaChoiceMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
  choiceId: Scalars['String']['input'];
}>;


export type SubmitMfaChoiceMutation = { __typename?: 'Mutation', inputMfaChoice: { __typename?: 'Result', status?: string, message?: string } };

export type SubmitMfaCodeMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  manual?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SubmitMfaCodeMutation = { __typename?: 'Mutation', inputMfaCode: { __typename?: 'Result', status?: string, message?: string } };

export type SubmitResendMfaCodeMutationVariables = Exact<{
  vendorAccountId: Scalars['ID']['input'];
}>;


export type SubmitResendMfaCodeMutation = { __typename?: 'Mutation', resendMfaCode: { __typename?: 'Result', status?: string, message?: string } };
