import { useEffect, useState, useRef } from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import makeStyles from '@mui/styles/makeStyles'
import { Store } from 'react-notifications-component'
import { useHistory } from 'react-router-dom'

import { colors } from '../../../global/colors'
import { strings } from '../../../global/strings'
import { useLogOut } from '../../../domain/auth/hooks'
import packageJson from '../../../../package.json'
import { useFeatureFlags, useFeatureFlagToggles } from '../../../domain/admin/flags/hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  text: {
    color: colors.white,
    fontSize: 12,
  }
}))

export function AccountMenu() {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)

  const [toggleCount, setToggleCount] = useState<number>(0)
  const [{ demoMode }] = useFeatureFlags()
  const { toggleDemoMode } = useFeatureFlagToggles()

  const anchorRef = useRef(null)
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)

  const logout = useLogOut()

  const handleToggle = () => {
    setOpen((opened) => !opened)
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // TODO: fix ref type issues
      // anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleClose = () => {
    // TODO: fix ref type issues
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }

    setOpen(false)
  }

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const onLogout = () => {
    handleClose()
    logout()
  }

  useEffect(() => {
    if (toggleCount > 3) {
      setToggleCount(0)
      toggleDemoMode(!demoMode)
      if (!demoMode) {
        Store.addNotification({
          title: 'Demo Mode Enabled',
          message: 'Demo mode successfully enabled',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true
          },
          slidingExit: {
            duration: 800,
            timingFunction: 'ease-out',
            delay: 0
          }
        })
      } else {
        Store.addNotification({
          title: 'Demo Mode Disabled',
          message: 'Demo mode disabled. User data is visible!',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            onScreen: true
          },
          slidingExit: {
            duration: 800,
            timingFunction: 'ease-out',
            delay: 0
          }
        })
      }
    }
  }, [toggleCount])

  return (
    <div className={classes.root}>
      <div>
        <Button
          disableElevation
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          data-ph-capture-attribute-details={'Clicked menu [ACCOUNT]'}
          onClick={handleToggle}>
          <p className={classes.text} data-testid='menu-account'>
            ACCOUNT
          </p>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper>
                <ClickAwayListener onClickAway={() => handleClose()}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" style={{ backgroundColor: colors.black, marginTop: -2 }} onKeyDown={(e) => handleListKeyDown(e)}>
                    {/* <MenuItem className={classes.text} onClick={() => handleClose()}>
                      {strings.manageServices}
                    </MenuItem> */}
                    <MenuItem
                      className={classes.text}
                      data-ph-capture-attribute-details={`Clicked menu [${strings.logout}]`}
                      data-testid='log-out' onClick={() => onLogout()}>
                      {strings.logout}
                    </MenuItem>
                    <MenuItem
                      data-ph-capture-attribute-details={'Clicked menu [licenses]'}
                      onClick={() => history.replace('/licenses')}
                      className={classes.text}
                      style={{ fontSize: 10, color: colors.grey500 }}>
                      {'licenses'}
                    </MenuItem>
                    <MenuItem
                      data-ph-capture-attribute-details={`Clicked menu [v${packageJson.version}]`}
                      onClick={() => setToggleCount(toggleCount + 1)}
                      className={classes.text}
                      style={{ fontSize: 10, color: colors.grey500 }}>
                      {`v${packageJson.version}`}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}
